<!-- Start Page Title Area -->
<!-- <div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Personal</h2>
            <p>All the tools you need</p>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<div class="contact-area pad-top-bot">
    <div class="container">
        <div class="section-title">
            <h2>TRAINING </h2>
            <div class="bar"></div>
        </div>
        <div class="row blog">
            <div class="col-md-12">
                <div id="blogCarousel1" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image2.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image3.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image4.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image5.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image6.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image7.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image8.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image9.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image10.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image11.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image12.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image13.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image14.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image15.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image16.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image17.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image18.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image19.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div> -->
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image20.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image21.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6  col-sm-6 m-t-10" style="margin-bottom: 20px;">
                                    <div>
                                        <img src="assets/img/gallery/training/image22.jpg" width="260" height="260"
                                            class="text-center">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row mix-grid thumbnails">
                                <div class="col-md-4 col-sm-6 mix category_1 mix_all" style="display: block;opacity: 1;">
                                    <div class="mix-inner"> <img alt="" src="assets/img/gallery/training/image22.jpg" class="img-responsive">
                                        <div class="mix-details"> <a data-rel="fancybox-button" href="assets/img/gallery/training/image22.jpg"
                                                class="mix-preview fancybox-button"><i class="fa fa-search"></i></a> </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mix category_1 mix_all" style="display: block; opacity: 1; ">
                                    <div class="mix-inner"> <img alt="" src="assets/img/gallery/training/image22.jpg" class="img-responsive">
                                        <div class="mix-details"> <a data-rel="fancybox-button" href="assets/img/gallery/training/image22.jpg"
                                                class="mix-preview fancybox-button"><i class="fa fa-search"></i></a> </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mix category_1 mix_all" style="display: block; opacity: 1; ">
                                    <div class="mix-inner"> <img alt="" src="assets/img/gallery/training/image22.jpg" class="img-responsive">
                                        <div class="mix-details"> <a data-rel="fancybox-button" href="assets/img/gallery/training/image22.jpg"
                                                class="mix-preview fancybox-button"><i class="fa fa-search"></i></a> </div>
                                    </div>
                                </div>
                            
                            </div> -->
                        </div>
                        <!--.item-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

