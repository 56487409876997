<!-- Start Page Title Area -->
<div class="page-title-area item-bg-aeps jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Aadhaar Enabled Payment System</h2>
            <p>OASYS PAY AEPS service enables our customers for hassle free and secure cash withdraw & balance enquiry
                from their aadhaar linked bank accounts.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->


<!-- Start Banner Area -->
<div class="banner-section" style="padding-top: 80px;">
    <div class="container">
        <div class="section-title">
            <h3>AEPS APP | CASH WITHDRAWAL PORTAL PROVIDER COMPANY | AGENT</h3>
            <div class="bar"></div>
        </div>
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="hero-content">
                    <h1>What is AEPS?</h1>
                    <p style="max-width: 515px;">Aadhaar enabled Payment System (AEPS) is a protected payment method provided by the National Payments Corporation of India (NPCI) that allows aadhaar linked bank account holders to avail basic banking.</p>
                    <p style="max-width: 515px;">Fingerprint authorization is done using a bio metric device to avail the service.</p>
                    <p style="max-width: 515px;">Customers simply need to tell their 12-digit Aadhaar number or VID (virtual ID) which is link to their respective Bank.</p>
                    <!-- <a routerLink="/" class="btn btn-primary">Create your account</a> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i> Play Video</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Information Area -->
<!-- <div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3>
                    <p>Free account available</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>10 minutes</h3>
                    <p>Apply for account</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>$100,000</h3>
                    <p>Guarantee on deposits</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Information Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Online Money Transfer Agency</h2>
                    <div class="bar"></div>
                    <p>Open your own online money transfer franchise with OASYS PAY and send money to bank account instantly through our highly secured money transfer business software and earn attractive commission.</p>
                    <p>We have more than 2 lakhs outlet in PAN India who are successfully working with us and are earning good.</p>
                    <p>Setup for this business is very easy, you just need one shop of any size and have OASYS PAY agent portal. Collect the money from the sender in cash, insert beneficiary’s account details, enter the amount and press send button and get
                        commission in every successful transaction. </p>
                    <p>Also it is very simple to top-up your OASYS PAY portal with debit card or credit card.</p>
                    <h2>How to Use?</h2>
                    <div class="bar"></div>
                    <p>Domestic money remittance as easy as mobile recharge you just need to collet the customer's mobile no, Beneficiary's bank account details and the amount to be transferred to the customer.</p>
                    <p>Complete the transaction by entering details at OASYS web portal or our android mobile app. Beneficiary's account will be credited instantly and the customer will also be notified via SMS with the details of the transaction in real time.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Benefits for Agents</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                    <ul class="services-list" style="width:605px;">
                        <li><span><i class="flaticon-check-mark"></i> Becoming OASYS PAY agent is a profitable business opportunity</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Register for AePS service and convert your shop to a mini ATM</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Perfect solution for their AePS related needs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> You can convert your shop in mini ATM</span></li>
                        <li><span><i class="flaticon-check-mark"></i>  long term business association</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Big profits with small investments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Earn Maximum and Instant Commission</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Earn Commission on every Transaction</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Increase the earning</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Become a trustable person</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Instant Commission Settlement</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content" style="max-width: 590px;">
                    <h2>Benefits for Customer</h2>
                    <div class="bar"></div>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Withdrawals of cash without any card via Aadhaar authentication</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Easy and secure cash withdrawal and balance enquiry.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AEPS has approach to every section of the society, so that even rural communities and elderly people of the urban areas can utilize the fast and reliable basic banking service.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Any bank account holder or customer can access banking services by simply identifying their identity with the help of biometrics and Aadhaar numbers without having to remember the account number.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Inter-operable across multiple banks.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Withdraw cash from account of any bank without visiting bank or ATM</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Service availability beyond banking hours and holidays</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Safe; completely digital; paperless</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Fast, hassle free, using only fingerprint</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cash Withdrawal</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Balance Enquiry</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mini Statement</span></li>
                        
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Services Offered By AEPS</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                    <ul class="services-list-aeps">
                        <li><span><i class="flaticon-check-mark"></i> Cash Withdrawal</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Balance Enquiry</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mini Statement</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FAQ section starts -->
<div class="banner-section-aeps bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What are the services available under AePS?
                            </a>
                            <div class="accordion-content fast">
                                <p>Under AePS currently,the following services are present:</p>
                                <ul class="article-features-list">
                                    <li>Cash Withdrawal</li>
                                    <li> Balance Enquiry</li>
                                    <li>Mini statement</li>
                                </ul>
                            </div>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What is eKYC Service?
                            </a>
                            <p class="accordion-content">eKYC is Electronic Know Your Customer. It is an electronic way
                                of doing real-time KYC of a customer using Aadhaar authentication. The Customer has to
                                provide his/her Aadhaar number and biometrics/OTP. eKYC enables an organization to get
                                an electronic copy of customer identity and address details, as present in the UIDAI
                                database, with customer consent</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What are the benefits of AePS?
                            </a>
                            <div class="accordion-content">
                                <p>The benefits of AePS are as follow:</p>
                                <ul class="article-features-list">
                                    <li>Aadhaar enabled Payment System is easy to use, safe, and secure payment platform
                                        to avail benefits by using Aadhaar number & biometrics.</li>
                                    <li>Aadhaar enabled Payment System is based on the demographic and biometric/iris
                                        information of an individual, it eliminates the threat of any fraud and
                                        non-genuine activity.</li>
                                    <li>Aadhaar enabled the Payment System to facilitate disbursements of Government
                                        entitlements like NREGA, Social Security pension, Handicapped Old Age Pension,
                                        etc. of any Central or State Government bodies using Aadhaar authentication.
                                    </li>
                                    <li>Aadhaar enabled Payment System facilitates inter-operability across banks in a
                                        safe and secured manner </li>
                                    <li>Reaching the unreached - The model enables banks to extend financial services to
                                        the unreached clients beyond their branch network as beneficiaries of the BCs
                                        are mostly located in unbanked and underbanked areas. </li>
                                </ul>
                            </div>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What are the benefits of eKYC?
                            </a>
                            <div class="accordion-content">
                                <p>eKYC as a product has the following benefits: </p>
                                <ul class="article-features-list">
                                    <li>Paperless</li>
                                    <li>Cost-effective</li>
                                    <li>Prevent Identity hacking and forged documents </li>
                                    <li>Safe & Secure</li>
                                    <li>Instantaneous </li>
                                </ul>
                            </div>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What is RRN No.?
                            </a>
                            <div class="accordion-content">
                                <p>RRN number is a 12 digit number generated to record the transaction and to identify a
                                    transaction uniquely.
                                </p>
                                <p>What I can do when transactions declined at the BC location and the account gets
                                    debited/credited incorrectly by the acquirer/issuer Bank?</p>
                                <p>In such a situation one should visit the bank where his/her account is located i.e.
                                    the nearest branch of the issuer bank.</p>
                            </div>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What is BC?
                            </a>
                            <p class="accordion-content">Business Correspondent (BC) is an approved Bank Agent providing
                                basic banking service using a MicroATM (terminal) to any bank customer wishing to avail
                                their bank BC service.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FAQ section ends -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->