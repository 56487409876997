<!-- Start Page Title Area -->
<div class="page-title-area item-bg-privacy jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <!-- <p>Submit your Feedback</p> -->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container text-justify">
        <div class="section-title">
            <h2>PRIVACY POLICY</h2>
            <div class="bar"></div>
        </div>
        <h5 class="sub-headings1 m-t-30 m-b-30">Updated on 5th July 2023</h5>
        <p>
            This policy applies to Oasys Cybernetics Private Limited a company incorporated under the Companies Act, 2013 with its registered office at OAS Towers, No.3, Stringers road, Periamet, Vepery, Chennai – 600003, Tamil Nadu, India and its Entities/Subsidiaries/Sister Concerns including but not limited to Oasys Cybernetics Private Limited, Oasys One Stop Solution Private Limited, (collectively “OASYS”, “we”, “our”, or “us” as the context may require). The words “You”, “Your” or “Yours” refer to any Customer, Merchant, Distributors, Partners who is using, accessing or browsing or has used or accessed or browsed the site (as defined below) and/is using or accessing or has used or accessed any version of OASYS Platform and/or has availed the services offered by OASYS. The terms / words not defined herein shall have the same meanings as defined in the Terms & Conditions (as defined below) applicable to the use or user of OASYS services and MIS accessing OASYS website or any other website / sub-domain created by OASYS, as may be modified by OASYS from time to time and the use of user of the App.
        </p>
        <p>
            This policy describes how OASYS collects, stores, uses and otherwise processes your Personal Information through OASYS websites, OASYS Applications, m-sites, chatbots, notifications or any other medium used by OASYS to provide its services to you or the other similar Platforms (hereinafter referred to as an <span class="font-wt-600">“Platform”</span> and/or <span class="font-wt-600">“OASYS PAY”</span>). By visiting, downloading, using OASYS Platform, and/or, providing your information or availing our product/services, you expressly agree to be bound by this Privacy Policy (“Policy”) and the applicable service/product terms and conditions. We value the trust you place in us and respect your privacy, maintaining the highest standards for secure transactions and protection of your personal information.
        </p>
        <p>
            This Privacy Policy is published and shall be construed in accordance with the provisions of Indian laws and regulations including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 under the Information Technology Act, 2000, the Aadhaar Act, 2016 and its Amendments, including the Aadhaar Regulations; that require publishing of the privacy policy for collection, use, storage, transfer, disclosure of Personal Information. Personal Information means and includes all information that can be linked to a specific individual and also includes Sensitive Personal Information (all Personal Information which requires heightened data protection measures due to its sensitive and personal nature), both, hereinafter referred to as <span class="font-wt-600">“Personal Information”</span>, excluding any information that is freely available or accessible in public domain. Please note, we do not offer any product/service under our Platform outside India. If you do not agree with this Privacy Policy, please do not use or access our Platform.
        </p>
        <p>
            OASYS is an innovative technology service provider for providing digital, financial and utility services through their partner network. The website is created to bring a one stop solution which can be availed by the customers through You. OASYS values Your privacy and security and follows the highest industry standards and practices to ensure the information You provide us is secure. Your information may be shared with our group or affiliates companies comply with the same level of data privacy standards and practices as are adhered to by OASYS.
        </p>
        <p>
            This Privacy Policy applies to all transactions that takes place through the OASYS Platform, and are in addition to, and not in derogation of, the applicable Terms and Conditions relating to Your usage of any other services offered by OASYS that You may be currently availing or may avail in future. This Privacy Policy is incorporated in the terms of use and therefore governs Your use of the OASYS website and/or the App and/or any services offered by OASYS. By visiting the Site and/or using/accessing the App and/or utilizing any services offered by OASYS, You agree to and accept the then current practices and policies governing Your use of the OASYS website and/or the App and/or utilizing any services offered by OASYS.
        </p>
        <p>
            As a user of the Site and/or the App and/or the services offered by OASYS, Your access to or use of the Site and/or the App and/or the services offered by OASYS, as the case may be, is conditional upon Your acceptance of and continued compliance with this Privacy Policy. By using the Site and/or the App and/or the services offered by OASYS, You agree to be bound by this Privacy Policy and all rules, guidelines and/or policies of OASYS, as may be modified by OASYS from time to time, unconditionally and at all times, You further agree and also confirm that You consent to providing OASYS with Your personal information for the purpose set out below and You understand that such collection, storage and handling is lawful, necessary and permissible under the applicable laws.
        </p>
        <h5 class="sub-headings1 m-t-30 m-b-30">1. Information Collection</h5>
        <p>
            We may collect your Personal Information when you use our services or Platform or otherwise interact with us during the course of our relationship. We collect Personal Information which is relevant and absolutely necessary for providing the services requested by you and to continually improve the OASYS Platform.
        </p>
        <p>
            1.1  The information collected is relevant and necessary to understand and on-board You for access to Site and/or the App and/or the services offered by OASYS. The information is collected with an intention to provide You with updated information, help regarding usage of the Site and/or the App and/or the services, offers, if any offered by OASYS that we think You might be interested in, to carry out follow ups, to assist with bill payment services and/or technical support issues, to enforce the Terms & Conditions of OASYS or to prevent fraud and unlawful use. We will use the information provided by You only for the purposes for which it is consensually collected, as more particularly described hereunder and/or indicated to You while using/accessing the Site and/or the App.
        </p>
        <p>
            1.2  To avail certain services on the Site and/or the App, You are required to provide Personal and Sensitive Personal Information collected, as applicable, includes, but are not limited to:
        </p>
        <ul>
            <li class="m-t-10">Your Name, Email Address, Phone Number, Date of Birth, PAN card details, Address Proof details, Photograph of User,
                Photograph of User’s shop, Access to Your phone’s text messages (SMS) inbox records/logs, Phone contacts, Location
                information (GPS, IP address and cell tower location), E-pos device id, Your banking details like Bank Account
                Number, Bank Name, Bank Branch address, IFSC code, Information pertaining to Your mobile/e-pos device, GST
                Certificate, if applicable (n) User On-boarding form, and names of other applications installed on Your mobile/e-pos
                device and other such information as required.</li>
            <li class="m-t-10">
                PAN card number, KYC related information such as videos or other online/ offline verification documents as mandated
                by relevant regulatory authorities, your business-related information</li>
            <li class="m-t-10">your device details such as device identifier, internet bandwidth, mobile device model, browser plug-ins, and
                cookies or similar technologies that may identify your browser/OASYS Applications and plug-ins, and time spent, IP
                address and location.</li>
            <li class="m-t-10">your Short Messaging Service (SMS(es)) that are stored on your device for the purposes of, including but not limited
                to, registering your device for payments services, OTPs for logins and payments, enhancing your security, bill
                payments, keep track of multiple bank accounts of our users in one platform and also to establish their financial
                status and credit history and other services provided to You via OASYS platform. All required information is service
                dependent, and we may use the above said user information to maintain, protect, and improve the Site and/or the App
                and for developing new services.</li>
        </ul>
        <p>
            Information may be collected at various stages of your usage of the OASYS Platform such as:
        </p>
        <ul>
            <li class="m-t-10">visiting OASYS Platform.</li>
            <li class="m-t-10">registering on OASYS Platform as an “user” or “merchant” or any other relationship that may be governed by terms and conditions listed on OASYS Platform.</li>
            <li class="m-t-10">transacting or attempting to transact on OASYS Platform.</li>
            <li class="m-t-10">accessing links, e-mails, chat conversations, feedbacks, notifications sent or owned by OASYS Platform and if you opt to participate in our occasional surveys.</li>
            <li class="m-t-10">otherwise dealing with any of the OASYS Entities/Subsidiaries/Sister Concerns.</li>
            <li class="m-t-10">otherwise dealing with any of the OASYS Entities/Subsidiaries/Sister Concerns.</li>
            <li class="m-t-10">while applying for career opportunities with OASYS.
            </li>
            </ul>
        <p>
            We and our service providers or business partners may also collect your Personal Information from third parties or information made publicly available, as applicable, including but not limited to:
        </p>
        <ul>
            <li class="m-t-10">financial history and other information for the purpose of verifying and authenticating an investment transaction request you place with us to prevent suspicious transactions, or to comply with court judgements and bankruptcies, from credit reference and fraud prevention agencies.</li>
            <li class="m-t-10">vehicle-related information when you opt for vehicle insurance.</li>
            <li class="m-t-10">your resume, your past employment and educational qualification for background checks and verifications, through online or offline databases that are otherwise legitimately obtained in case you apply for employment opportunities with OASYS.</li>
            <li class="m-t-10">your demographic and photo information including but not limited to Masked Aadhaar number, address, gender, and date of birth as a response received from UIDAI upon successful Aadhaar e-KYC.</li>
        </ul>
        <h5 class="sub-headings1 m-t-30 m-b-30">2. Purpose and Use of Information</h5>
        <p>2.1 OASYS may process and use your Personal Information for the following purposes:</p>
        <ul>
            <li class="m-t-10">creation of your account and verification of your identity and access privileges.</li>
            <li class="m-t-10">provide you access to the products and services being offered by us.</li>
            <li class="m-t-10">
                fulfil your service request.</li>
            <li class="m-t-10">to conduct the KYC compliance process as a mandatory prerequisite as per the requirements of various regulatory bodies, including UIDAI under the Aadhaar Act and its Regulations.</li>
            <li class="m-t-10">
                to validate, process and/or share your KYC information, other details with other intermediaries, Regulated Entities (REs) or financial institutions or with any other service providers as may be required.</li>
            <li class="m-t-10">To procure credit reports from credit bureaus and identity verification services (such as those provided by UIDAI);</li>
            <li class="m-t-10">To help us process questions You may have about the Site and/or the App and/or service offered by OASYS;</li>
            <li class="m-t-10">To confirm and/or getting correct information what we know about You;</li>
            <li class="m-t-10">To provide financial and digital payment services (such as AEPS, Aadhaar Pay, Bharat Bill Pay, DMT etc.) offered by OASYS</li>
            <li class="m-t-10">To help us or law enforcement agencies prevent fraud, money laundering, terrorism and other crimes. Also we may disclose Your Personal Information to the police, regulatory bodies or legal advisers in connection with any alleged criminal offence or suspected breach of this Privacy Policy and/or the Terms and Conditions or otherwise where required by law and we will co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity of or locate anyone breaching this Privacy Policy or otherwise for the prevention or detection of crime or the apprehension or prosecution of offenders;</li>
            <li class="m-t-10">Assisting an agency in undertaking information for us;</li>
            <li class="m-t-10">another organization/sister concern is engaged by us to perform tasks on our behalf;</li>
            <li class="m-t-10">If we sell all or any part of our business or merge with another company;</li>
            <li class="m-t-10">
                If it is necessary for legal proceedings and when we believe in good faith that any applicable law, regulation, government, regulatory or other authority, agency or officer requires it or to protect the safety or security of users of the Site and/or the App;</li>
            <li class="m-t-10">
                If we use third party processors to administer and process Your Personal Information for the purpose notified in this Privacy Policy, e.g. for hosting activities related to the use of the Site, and/or the App and/or the services offered by OASYS;</li>
            <li class="m-t-10">To obtain advice from third parties such as auditors or legal advisors.</li>
            <li class="m-t-10">With our group or affiliates companies to further enhance our products and services to You.</li>
            <li class="m-t-10">enhancing your user experience in various processes/submission of applications/availment of product/service offerings by analysing user behaviour on an aggregated basis.</li>
            <li class="m-t-10">
                to monitor and review products/services from time to time; customize the services to make your experience safer and easier, and conducting audits.</li>
            <li class="m-t-10">to allow third parties to contact you for products and services availed/requested by you on OASYS Platform or third-party links.</li>
            <li class="m-t-10">to carry credit checks, screenings or due diligence checks as lawfully required by us; detect and protect us against error, fraud, money laundering and other criminal activity; enforce our terms and conditions.</li>
            <li class="m-t-10">to inform you about online and offline offers, products, services, and updates; customizing and improving your experience by marketing, presenting advertising, and offering tailored products and offers.</li>
            <li class="m-t-10">to resolve disputes; troubleshoot problems; technical support and fixing bugs; help promote a safe service.</li>
            <li class="m-t-10">to identify security breaches and attacks; investigating, preventing, and taking action on illegal or suspected fraud or money laundering activities and conducting forensic audits as part of internal or external audit or investigation by OASYS or our partner bank’s appointed auditors, government agencies, RBI, its officials, any authority appointed by RBI and/or any regulatory body;</li>
            <li class="m-t-10">to meet legal obligations.</li>
        </ul>
        <p>
            While we may also process your Personal Information for other legitimate business cases, we ensure to take appropriate steps to minimize the processing to the extent possible, making it less intrusive to your privacy.
        </p>
        <p>Please note that when providing you with eKYC and AePS services, we do not store, use, or have any access to Aadhaar or biometric data.</p>
        <p>
            2.2  Any personal and sensitive information provided by You will not be considered as sensitive, if it is freely available and/or accessible in the public domain. Further, any review, comments, messages, blogs posted/uploaded/ conveyed/communicated by users on the public sections of the Site or an Application Store like (the App Store or Play Store) becomes published content and is not considered personal and sensitive information subject to this Privacy Policy.
        </p>
        <p>
            2.3  In case You chose to decline to submit personal and sensitive information on the Site/App, we may not be able to provide complete access of Site/App and/or certain services on the Site/App to You. We will make reasonable efforts to notify You of the same at the appropriate time. In any case, we will not be liable and/or responsible for the denial of complete or certain services to You for lack of You providing the necessary personal information.
        </p>
        <p>
            2.4  When You register with the Site and/or the App, we may contact You from time to time about updation of Your personal information to provide such features that we believe may benefit/interest You.
        </p>
        <p>
            2.5  Your Personal Information may be used by us to:
        </p>
        <ul>
            <li class="m-t-10">Monitor, develop and improve the Site, the App and services offered by OASYS and Your experience;</li>
            <li class="m-t-10">
                Assist in and administer the provision of services offered by OASYS to You;</li>
            <li class="m-t-10">
                Process and deal with any complaints or enquiries made by or about You, and</li>
            <li class="m-t-10">
                Investigate and suspected breach of the Terms and Conditions or the breach of other terms and conditions otherwise relating to You and to monitor compliance including by way of checking postings or submissions to the Site and/or the App.</li>
        </ul>
        <p>
            2.6  You hereby agree and acknowledge that by accessing and browsing the Site and/or the App and using the App, You consent to OASYS undertaking the following:
        </p>
        <ul>
            <li class="m-t-10">Send You and keep You updated with information by email, telephone (including SMS), notification on Site and/or the App or mail about existing and new services and benefits from us;</li>
            <li class="m-t-10">Use the information for marketing and for market research/industry/sector analytics purposes, including internal demographic studies to provide, optimize and personalize our services and to further enhance our products and services for You and to send You newsletters and information about our services.
            </li>
        </ul>
        <h5 class="sub-headings1 m-t-30 m-b-30">3. Information Sharing and Disclosures</h5>
        <p>Your Personal Information is shared as allowed under applicable laws, after following due diligence and in line with the purposes set out in this Policy.</p>
        <p>3.1  We may share your Personal Information in the course of your transaction with different categories of recipients such as business partners, service providers, partners, merchants, entities, subsidiaries, legally recognized authorities, regulatory bodies, governmental authorities, financial institutions, internal teams such as marketing, security, risk management, investigation team, etc.</p>
        <p>3.2  Personal Information will be shared, as applicable, on need-to-know basis, for the following purposes:
        </p>
        <ul>
            <li class="m-t-10">for enabling the provision of the products/services availed by you and facilitating the services between you and the service provider, partners, as requested</li>
            <li class="m-t-10">for the Aadhaar authentication process.</li>
            <li class="m-t-10">for complying with applicable laws as well as meeting the Know Your Customer (KYC) requirements as mandated by various regulatory bodies, whose regulated service/product you opt through our services/Platforms</li>
            <li class="m-t-10">for the purpose of processing your financial product subscription requests placed with us and ensuring that these requests reach the relevant financial institution whose service/product you have opted for</li>
            <li class="m-t-10">
                if it is required by financial institutions to verify, mitigate, or prevent fraud or to manage risk or recover funds in accordance with applicable laws/regulations</li>
            <li class="m-t-10">for services related to communication, marketing, data and information storage, transmission, security, analytics, fraud detection, risk assessment and research</li>
            <li class="m-t-10">enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting, or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public</li>
            <li class="m-t-10">
            if required to do so by law or in good faith we believe that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process</li>
            <li class="m-t-10">if requested by government authorities for government initiatives and benefits</li>
            <li class="m-t-10">for grievance redressal and resolution of disputes</li>
            <li class="m-t-10">with the internal investigation department within OASYS or agencies appointed by OASYS for investigation purposes located within or outside the Indian jurisdiction</li>
            <li class="m-t-10">investigation by OASYS’s partner bank’s appointed auditors, government agencies, RBI, its officials, any authority appointed by RBI and/or any regulatory body;</li>
            <li class="m-t-10">should we (or our assets) plan to merge with, or be acquired by any business entity, or re-organisation, amalgamation, restructuring of our business then with such other business entity</li>
            <li class="m-t-10">We may pass Your Personal Information to our employees, contractors, suppliers and/or agents to administer the services provided to You by us or in the future;</li>
        </ul>
        <p>
            3.3  In addition to the above, disclosures may also be made if it was clearly stated at the time of registration and, where required, if You have given permission for this. Generally, we will disclose only the information we consider reasonably necessary to disclose. Also, some general statistical information about the Site/App user base, traffic volumes and related matters may be passed on the reputable third parties but these details will not include information identifying You.
        </p>
        <p>
            3.4  In the event You submit any comment of feedback on the Site and/or the App, we may (but not obliged to) publish Your comments or feedback (including images) online or offline to promote our business and services. We will collect You name which will be displayed next to Your comment, and e-mail address, which will not be published, but which we may use to contact You in relation to Your comment. From time to time, we may ask You for Your opinion on our services. When we do surveys wherein we may use Cookies (as defined below) or other on-device storage and may combine the information collected by those Cookies and other on-device storage with Your answers. We will make sure that surveys results do not identify You, or anyone else who fills in our surveys.
        </p>
        <p>
            3.5  The information is readily available on the Site and/or the App and only viewable by You after logging on to the Site and/or the App. You, at all times, have the option not to provide any Personal Information or other information about You without agreeing to register on the Site and/or the App and otherwise withdraw Your consent or request to remove/ delete Your information by communicating the same to us in the manner stipulated in the Terms and Conditions and the policies of OASYS. Upon withdrawal, OASYS will have no obligations towards You nor will it be liable in relation to any services offered by OASYS during the time You had consented to retain the information with OASYS. Alternatively, You can uninstall the App in its entirety, in which event, Your consent would be deemed to be withdrawn.
        </p>
        <p>
            3.6  Please note that if You opt out from providing personal or other information requested by us, You may not be able to access complete and/or certain areas of the Site and/or the App and/or avail certain services offered by OASYS. However, it may be noted that OASYS reserves the right to send service related and Your specific and preferred transaction related communication without offering You the facility to opt-out. You can update Your Account Information (defined below), Personal Information and other information at any time. At Your request, OASYS can also cancel Your registration and remove/block Your Personal Information from its database/ Site and/or the App. However, this information may remain in our archive even after deletion or termination of Your account as required by law and the handling of the same is in accordance with the provisions of the applicable law and as intimated by the government agency from time to time.
        </p>
        <p>
            3.7  In relation to any payment made through the Site and/or the App and/or the services offered by OASYS, the online payment gateway operated by third party is being used by OASYS. It may be noted that the information that You provide at the time of making any payment through the Site and/or the App and/or services offered by OASYS is held and stored with third party payment gateway operator authorized at multiple points. The payment gateway operator may also have access to Your online payment history/details in relation to the payment made through the Site and/or the App and/or the services offered by OASYS. These payment gateways are digitally encrypted, thereby providing the highest possible degree of privacy and care available, currently. Whilst we are unable to guarantee 100% (One Hundred percent) security, this makes it hard for a hacker to decrypt Your details. You are strongly recommended not to send full credit or debit card details in unencrypted electronic communications with us. Also, You are responsible for protecting against unauthorized access to Your password and to Your computer or mobile or e-pos terminal. We will not be liable for the disclosure and dissemination of Personal Information to third parties which You as prudent user put up on the Site and/or the App, and available to the other users of the Site and/or the App.
        </p>
        <p>
            3.8  OASYS handles all such information in accordance with the provisions of the applicable law as may be applicable from time to time and only for the duration lawfully required, there under. OASYS may disclose aggregated information, including to aggregators, about the use of the Site and/or the App without Personal Information. OASYS implements standard measures to protect against unauthorized access to and unlawful interception of Personal Information. However, OASYS feels prudent to inform You that no internet site can fully eliminate security risks and enforcement of privacy laws are at a developing stage in some parts of the world.
        </p>
        <p>
            3.9  While the information is shared with third parties as per purposes set out in this Policy, processing of your Personal Information is governed by their policies. OASYS ensures stricter or no less stringent privacy protection obligations are cast on these third-parties, wherever applicable and to the extent possible. However, OASYS may share Personal Information with third-parties such as legally recognized authorities, regulatory bodies, governmental authorities, and financial institutions as per purposes set out in this Policy or as per applicable laws. We do not accept any responsibility or liability for usage of your Personal Information by these third parties or their policies.
        </p>
        <h5 class="sub-headings1 m-t-30 m-b-30">4. Use of Cookies or Similar Technologies</h5>
        <p>
            4.1  We use data collection devices such as "cookies" or similar technologies on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your device hard-drive/storage that assist us in providing our services. Cookies do not contain any of your Personal Information. We offer certain features that are only available through the use of a "cookie" or similar technologies. We also use cookies to allow you to enter your password less frequently during a session. Cookies or similar technologies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your device hard-drive/storage at the end of a session. You are always free to decline/delete our cookies or similar technologies if your browser/device permits, although in that case you may not be able to use certain features on the Platform.
        </p>
        <p>
            4.2  By using or accessing the Site, our servers (hosted by a third party service provider) may collect information indirectly and automatically about Your activities on the Site by way of cookies. For the purpose of this Privacy Policy, “Cookies” are pieces of information that a site sends to Your computer or other media device while You are viewing or interacting with the site. We use Cookies and other such devices to compile anonymous, aggregated statistics that allow us to understand how users use our Site and to help us improve the structure of our Site (we cannot identify You personally in this way). Further it allows us to understand who has seen which pages and advertisements on the Site, to determine how frequently such particular pages and advertisements are visited, to determine the most popular areas of the Site and generally in order to monitor usage of the Site. We may combine Your visitor session information or other information collected through Cookies and other technical methods with personally identifiable information in order to understand and improve Your online experiences and to determine what category/nature of businesses and locations You prefer or are likely to be of interest to You. This information is not linked to Your Personal Information and is maintained distinctly.
        </p>
        <p>
            4.3  You may also get Cookies from our advertisers. We do not control these Cookies, and once You have clicked on the advertisement and left the Site, our Privacy Policy no longer applies. OASYS is not responsible for collecting Cookies through Linked Sites (defined below), neither is it liable for misuse of the information You provide to Linked Sites.
        </p>
        <p>
            4.4  It is clarified that we store information including Personal Information only in the manner contained herein and in accordance with the provisions of applicable law.

        </p>
        <p>
            4.5  You can change Your web browsers settings to accept or not to accept Cookies. OASYS may use third-party service providers to serve ads on its behalf across the internet and sometimes on the Site. Such third party service providers may collect anonymous information about Your visits to the Site and use of the services offered by OASYS. Personally, identifiable information is not linked to data collected from this source.
        </p>
        <p>
            4.6  The Site could use log file including internet protocol addresses, browser type, internet service provider, referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the Site, track user movement and gather broad demographic information for aggregate use. These are not linked to information identifiable personally.
        </p>
        <h5 class="sub-headings1 m-t-30 m-b-30">5. Storage and Retention</h5>
        <p>To the extent applicable, we store Personal Information within India and retain it in accordance with applicable laws and for a period no longer than it is required for the purpose for which it was collected. However, we may retain Personal Information related to you if we believe it may be necessary to prevent fraud or future abuse or if required by law such as in the event of the pendency of any legal/regulatory proceeding or receipt of any legal and/or regulatory direction to that effect or for other legitimate purposes.</p>
        <p>Once the Personal Information has reached its retention period, it shall be deleted in compliance with applicable laws.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">6. Reasonable Security Practices and Consequences</h5>
        <p>6.1  OASYS has deployed administrative, technical, and physical security measures to safeguard user’s Personal Information and Sensitive Personal Information. Specifically, in order to safeguard your information, we have implemented applicable security controls. We understand that as effective as our security measures are, no security system is impenetrable. Hence, as part of our reasonable security practices, we undergo strict internal and external reviews to ensure appropriate information security encryption or controls are placed for both data in motion and data at rest within our network and servers respectively. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited.</p>
        <p>6.2  We have provided multiple levels of security to safeguard the OASYS PAY Application by login/logout option and OASYS PAY Application lock feature (“Enable Screen Lock”) that can be enabled by you.</p>
        <p>6.3  We have preventive controls implemented to ensure you use OASYS PAY Application on your device and the same login credentials cannot be used on different device without any additional authentication/OTP.</p>
        <p>6.4  While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service or responding to legal requests) are granted access to Personal Information. The servers in which we store Personal Information and payment information are kept in a secure environment with required isolation.</p>
        <p>6.5  OASYS strives to ensure the security, integrity protection and privacy of Your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. We follow stringent security techniques and requirements for handling sensitive and Personal Information. These techniques and requirements are fully compliant with the guidelines set forth under the applicable laws. Our servers are accessible only to authorized personnel. Your information covered under this Privacy Policy is shared with respective personnel only on need to know basis and/or to provide the services You have requested. All representatives handling information under these provisions are under contractual confidentiality obligation with OASYS. While we take every step to safeguard the confidentiality of Your Personal Information, by using the Site and/or the App, You agree and confirm that OASYS is not liable for any negligence, disclosure due to errors in transmission or unauthorized acts of third parties or Linked Sites.</p>
        <p>6.6  Notwithstanding the foregoing, You must keep account details, including Your username and password (“Account Information”), OTP (“One Time Password”) confidential at all times, and should not reveal the same to us or any third parties. You must take appropriate security measures to prevent unauthorized disclosure of Your Account Information (including logging out from Your account at the end of each session), and You must notify us immediately if Your Account Information becomes known to any unauthorized user. You are solely responsible for maintaining the confidentiality of Your Account Information, and all activities that occur under Your account(s). Any breach of this Privacy Policy by anyone to whom You disclose Your account details, including Your username and password, OTP send to Your registered mobile number will be treated as if the breach had been committed by You, and will not relieve You of Your obligations under this Privacy Policy and the Terms and Conditions.</p>
        <p>6.7  Notwithstanding anything contained herein, OASYS shall not be liable for any loss, damage or misuse of Your Personal Information due to a Force Majeure Event. A “Force Majeure Event” for the purposes of this Privacy Policy shall mean any event that is beyond reasonable control of OASYS and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer and/or software hacking, unauthorised access to computer data and storage device, computer viruses breach of security and encryption or any other cause beyond the control of OASYS.</p>
        <p>6.8  OASYS will not be liable for indirect, incidental, special, consequential, or exemplary damages (including any loss of revenue, profits, goodwill, use, or data), OASYS platform services even if OASYS has been advised of the possibility of those damages. OASYS disclaims all warranties, express or implied, written or oral, including, but not limited to, warranties of merchantability and fitness for a particular purpose with respect to the OASYS technology, OASYS Payment Platform or OASYS Platform Services or otherwise made. OASYS, also reserves the right to refuse to transfer money in respect of transactions from Customers with a prior history of non-payments, questionable charges and any litigation arisen from similar services provided by OASYS to the Customers before. The financial transactions conducted through the OASYS Payment Platform are linked to your registered mobile number. OASYS will not be held liable for unauthorized use of OASYS Payment Platform as a result of loss/theft/misplacement of mobile number and/or SIM, unless you have informed OASYS by submitting written application about such loss/theft/misplacement and have blocked OASYS account temporarily till you reactivate the same using a new SIM with same mobile number. OASYS Payment Platform is available for use in India only and cannot be used for cross-border transactions.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">7.  Third-Party Products, Services, or Websites</h5>
        <p>When you are availing products and services of service providers on OASYS Platform, Personal Information may be collected by respective service providers and such Personal Information shall be governed by their privacy policy. You may refer to their privacy policy and terms of service to understand how your Personal Information will be handled by such service providers.</p>
        <p>Our services may include links to other websites or applications when you visit our Platform. Such websites or applications are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser or on the m-site you are redirected to), use of any Personal Information that you provide on these websites or applications is governed by the privacy policy of the operator of the application/website, you are visiting. That policy may differ from ours and you are requested to review those policies or seek access to the policies from the domain owner before proceeding to use those applications or websites. We do not accept any responsibility or liability for usage of your Personal Information by these third parties or their policies.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">8.  Your Consent and Acceptance of Terms</h5>
        <p>8.1 We process your Personal Information with consent. By using the OASYS Platform or services and/or by providing your Personal Information, you consent to the processing of your Personal Information by OASYS in accordance with this Privacy Policy. If you disclose to us any Personal Information relating to other people, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.</p>
        <p>8.2 By using, accessing, clicking any relevant icon or button on the Site and/or the App and/or registering on the Site and/or the App or using the services offered by OASYS, You are deemed to have agreed to the terms of this Privacy Policy, the Terms and Conditions and the fact that we collect, store, handle, process and share/ transfer Your information, including Personal Information and Account Information. This Privacy Policy should, at all times, be read along with the Terms and Conditions and the policies of the Site and the App.</p>
        <p>8.3 By using the Services, You agree and acknowledge that Your Personal Information may be transferred within national boundaries. You acknowledge that in the process of providing the services through the Site and/or the App, some activities relating to handling of information collected under the Privacy Policy may be done by OASYS’s trusted third-party vendors or agents, web hosting providers, communication services, and web analytic providers who are binding by our privacy policy.</p>
        <p>8.4 By availing services offered by OASYS, You accept that OASYS may communicate with You about our products, services, offers, events and promotions, and offer You products and services we believe may be of interest to You. This includes offers, discounts and general information.</p>

        <h5 class="sub-headings1 m-t-30 m-b-30">9.  Registration or On-boarding</h5>
        <p>To avail any of the services offered by OASYS, You are required to register and open an account with us by using the Account Information. You agree to provide correct information towards Your continued usage of services offered by OASYS. OASYS will save Your General, KYC, Account Information, including identification (as profile), payment (as saved accounts) and transaction (as transaction history) related information to process Your requests faster and securely for Your convenience.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">10.  Personal Information Access/Rectification and Consent</h5>
        <p>You can access and review your Personal Information shared by you by placing a request with us. In addition, you may at any time revoke consent given to us to store your information. Upon such revocation, you may lose access to services that were availed on the basis of the consent provided.</p>
        <p>In some cases, we may continue to retain your information as per the ‘Storage and Retention’ section of this Policy. To raise any of the above requests, you may write to us using the contact information provided under the ‘Contact Us’ section of this Policy.</p>
        <p>In case you wish to delete your account or Personal Information, please use the ‘Help’ section of the OASYS Platform. However, retention of your Personal Information will be subject to applicable laws.</p>
        <p>For the above requests, OASYS may need to request specific information from you to confirm your identity and ensure authentication. This is a security measure to ensure that Personal Information is not disclosed to any person who does not have a right to receive it or is not incorrectly modified or deleted.</p>
        <p>In cases where you need any further information specific to the product/ services that you are availing, we request you to read through the Terms and Conditions specific to the product/service which is easily accessible through the OASYS Platform. For seeking any further information on the same, you can write to us at the details mentioned in the ‘Contact Us’ section of this Policy.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">11.  Changes to Policy</h5>
        <p>Since our business changes constantly, so will our policies. We reserve the right, at our sole discretion, to change, modify, add, or remove portions of this Privacy Policy at any time without any prior written notice to you. We may, however, reasonably endeavour to notify you of the changes, it is your responsibility to review the Privacy Policy periodically for updates/changes. Your continued use of our services/Platform, following the posting of changes will mean that you accept and agree to the revisions. We will never make changes to policies in order to make it less protective of Personal Information already shared by you.</p>
        <p>We will not file or store a copy of these terms for each interaction on transaction by You through the Site and/or the App. We therefore recommend that You save a copy of this Privacy Policy for future reference, and ensure that such copies can be reliably verified as being effective (i.e. published on the Site and/or the App) on a particular date.</p>
        <h5 class="sub-headings1 m-t-30 m-b-30">12.  Contact Us</h5>
        <p>12.1 In case You do not want to give us access to any information, including any Personal Information or Account Information, You may choose to opt-out of all promotional/non-promotional communications or if You have any questions or comments about this Privacy Policy, or if You would like us to update information we have about You or Your preferences or You have notice any discrepancies in the information provided to us or have any grievance against us, You may mail us at <span class="font-wt-600">customercare@oasyspay.in </span>or You may call us on <span class="font-wt-600">1800-572-2700 </span>(Monday to Friday @ 10:00 AM to 6:00 PM)</p>
        <p>12.2 You can do the following at any time by contacting us via the email address or toll-free number provided as above to:</p>
        <ul>
            <li class="m-t-10">Express any concern You have about OASYS services.</li>
            <li class="m-t-10">Express any concern You have about our use of Your data.</li>
            <li class="m-t-10">To know about the process of using services.</li>
            <li class="m-t-10">Service Charges and Commissions of any specific services.</li>
            <li class="m-t-10">Check what data we have about You, if any.</li>
            <li class="m-t-10">Change and/or correct any data we have about You.</li>
        </ul>
        <h5 class="sub-headings1 m-t-30 m-b-30">13.  Disputes</h5>
        <p>Any disputes over inter alia collection, storage and handling of Personal Information will be governed by this Privacy Policy, Terms and Conditions and by the laws of India and courts of Chennai shall have exclusive jurisdiction.</p>
    </div>
</div>