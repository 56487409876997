<!-- Start Page Title Area -->
<div class="page-title-area item-bg-aeps jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Aadhaar Enabled Payment System</h2>
            <p>OASYS PAY AEPS service enables our customers for hassle free and secure cash withdraw & balance enquiry
                from their aadhaar linked bank accounts.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->
<!-- Start Banner Area -->
<div class="banner-section" style="padding-top: 100px;">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-12 col-md-12 p-0">
                <div class="hero-content-aeps">
                    <h1>Prepaid and DTH Recharge</h1>
                    <p>The telecom industry is growing very fast in India. Airtel, V!,Jio, and BSNL are the major players in this sector. For Cell recharge, more often than not, users have to physically go to shops to recharge phone cards. Our prepaid online recharge is a one-stop solution for providing easy and fast recharge of prepaid mobile phones
                    through the Web, Android app, POS, and that too at competitive prices.
                    OSS Pay app is Multi Recharge LAPU Retailer Panel helps you to recharge all major prepaid, DTH
                    providers.</p>

                    <p>OSS has developed a unique multi recharge business platform that brings together a variety of
                        prepaid mobile, DTH
                        operators.
                        <br>We support API mode recharge because we want to serve a large customer base with different
                        requirements.
                        Although our API-based recharge service is faster. </p>
                    <p>
                        OSS not only provides you with easy access, but we also ensure fast and secure transactions. We
                        can complete
                        prepaid mobile recharge in less than 10 seconds.
                        <br>You can instantly recharge your mobile and get an attractive commission on every
                        transaction.
                    </p>
                    <!-- <a routerLink="/" class="btn btn-primary">Create your account</a> -->
                </div>
            </div>
            <!-- <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                            class="fas fa-play"></i> Play Video</a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Information Area -->
<!-- <div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3>
                    <p>Free account available</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>10 minutes</h3>
                    <p>Apply for account</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>$100,000</h3>
                    <p>Guarantee on deposits</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Information Area -->

<!-- Start Services Area -->
<div class="services-area ptb-40">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Prepaid Recharge Service for Merchant / Agent / Retailers</h2>
                    <div class="bar"></div>
                    <p>
                        One can become our partner and add extra to your income. You can start a mobilerecharge shop very easily and from your shop in your locality. 
                        <br>Our company will provideyou mobile recharge portal software for your business. You can recharge telecoms likeBSNL, V!,Jio, Airtel, etc. 
                        <br>We are the best multi recharge app for your businessand our success ratio is more than 99%. The mobile recharge retailer commission isvery attractive in low investment.
                    </p>
                    <h4>Prepaid Recharge</h4>
                    <p> With just a few clicks and all the required information, you can successfully trade and pay your bills. As a 100% secure payment option, it ensures the complete security of shared information. For prepaidusers, there are many plans and their information that you can use to make informedchoices before performing a top-up transaction. The prepaid portion of the networkincludes Airtel, V!,Jio, BSNL, etc. with great mobile recharge commission.</p>
                    <h4>DTH Recharge</h4>
                    <p> For uninterrupted DTH services, you can connect your Airtel DTH, Big TV, Dish TV, Sun TV, Tata Sky, and VideoconD2H to live instant charging, without missing your favorite shows to capture all live sports.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Benefits of Our Retailer Portal</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <!-- <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul> -->
                    <ul class="article-features-list" style="margin-top: 15px;">
                        <li>
                         <b>Single Wallet</b>: You get a single Wallet for providing all services. No require to manage separate balance for different services/operators.
                        </li>
    
                        <li>
                         <b>Detailed MIS/Report</b>: Track your business with us, get billing report, recharge history & earned commission details easily.
                        </li>
    
                        <li>
                         <b>Instant Commission</b>: We provide instant commission payout. So whenever you make any recharge you will get commission instantly.
                        </li>
    
                        <li>
                         <b>No area restriction</b>: Recharge from anywhere and any number all over India with your single wallet.
                        </li>
    
                        <li>
                         <b>Less Working Capital</b>: Upload your wallet, as or when you require. Load Wallet Anytime you want.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Some features of our charging system</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Start of business of an online recharge business with all a one recharge solution.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automatically coordinate pending recharge with various operators<</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Recharge business with high commission</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automatic refund in case of failure</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Support most carrier denominations/plans</span></li>
                        <li><span><i class="flaticon-check-mark"></i> High success rate</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png"
                            class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->