<!-- Start Page Title Area -->
<div class="page-title-area item-bg-credit jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start AEPS Services Area -->
<div class="services-area ptb-70" #focusCrm>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">Axis CRM</h1>
                    <div class="bar"></div>
                    <p>Axis CRM refers to credit services lead generation the process within Axis Bank's Customer Relationship Management (CRM) system of identifying and cultivating potential customers who may be interested in credit-related products and services offered by the bank. This process involves leveraging customer data and insights to identify individuals or businesses with a high likelihood of requiring credit, such as loans, credit cards, or other financing options.</p>
                    <p>Overall, Axis CRM credit services lead generation aims to optimize the bank's sales efforts, improve customer engagement, and drive growth in the credit services segment by effectively identifying and nurturing leads with the highest potential for conversion.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/products-services/axis-crm1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End AEPS Services Area -->

<!-- Start Micro ATM Services Area -->
<div class="services-area ptb-70 bg-f7fafd" #focusAgri>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/agri-based-loan.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 70% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">Agri Based Loan</h1>
                    <div class="bar"></div>
                    <p>Agricultural-based offers loans to support farmers and individuals engaged in agriculture-related activities. These loans are designed to meet the financial needs of farmers, agricultural businesses, and rural communities, helping them invest in agricultural equipment, machinery, inputs, and other essential resources.
                        <br/> Agricultural-based loans may include various products tailored to the specific needs of farmers, such as:
                    </p>
                    <ul class="services-list" style="margin-right: -60px;">
                        <li><span><i class="flaticon-check-mark"></i> Loans Against Tractor</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Track Based Personal Loans</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Implement Finance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Used Commercial Loans</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Micro ATM Services Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->