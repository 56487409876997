<!-- Start Page Title Area -->
<div class="page-title-area item-bg-g2c jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start AEPS Services Area -->
<div class="services-area ptb-70" #focusUmang>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">UMANG</h1>
                    <div class="bar"></div>
                    <p>The Permanent Account Number (PAN) card is a unique identification number issued by the Income Tax Department of India to individuals, businesses, and other entities. PAN card services encompass various functions related to the application, and correction of PAN cards.</p>
                    <p>Individuals can apply for a PAN card online or through our OASYS PAY agents by submitting the required details and completing the application form. </p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/products-services/UMANG1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End AEPS Services Area -->

<!-- Start Micro ATM Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/PAN.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 75% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">PAN Card Center</h1>
                    <div class="bar"></div>
                    <p>The Permanent Account Number (PAN) card is a unique identification number issued by the Income Tax Department of India to individuals, businesses, and other entities. PAN card services encompass various functions related to the application, and correction of PAN cards.</p>
                    <p>Individuals can apply for a PAN card online or through our OASYS PAY agents by submitting the required details and completing the application form. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Micro ATM Services Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->