import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-credit-services',
    templateUrl: './credit-services.component.html',
    styleUrls: ['./credit-services.component.scss']
})
export class CreditServicesComponent implements OnInit {

    @ViewChild('focusAgri')focusAgri: ElementRef;
    @ViewChild('focusCrm')focusCrm: ElementRef;
    services: string;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        this.services = localStorage.getItem('services');
    }

    ngAfterViewInit() {
        if (this.services == 'agriloan') {
            this.focusAgri.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        } else if (this.services == 'crm') {
            this.focusCrm.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        }
    }

    ngOnDestroy() {
        localStorage.setItem('services', '');
    }

}