<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                        <p>Quis ipsum suspendisse ultrices gravida commodo. Risus commodo veliliee vel viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>Products & Services</h3>

                    <ul class="list">
                        <li><a routerLink="/banking-services" (click)="readMore('dmt')">Money Transfer</a></li>
                        <li><a routerLink="/banking-services" (click)="readMore('aeps')">AEPS</a></li>
                        <li><a routerLink="/utility-bill-payment" (click)="readMore('bbps')">BBPS</a></li>
                        <li><a routerLink="/g2c-services" (click)="readMore('umang')">UMANG - G2C Services</a></li>
                        <li><a routerLink="/insurance" (click)="readMore('hospicash')">Hospicash</a></li>
                        <li><a routerLink="/credit-services" (click)="readMore('agriloan')">Agri Based Loan</a></li>
                        <li><a routerLink="/credit-services" (click)="readMore('crm')">Axis CRM</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li>
                            <a routerLink="/terms-conditions">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/privacy-policy">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact">Contact Us</a>
                        </li>
                        <li><a routerLink="/about-us">About Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><i class="fa-solid fa-location-dot" style="color: #9661C7;">&nbsp;&nbsp;</i> OASYS One Stop Solution Pvt Ltd,<br>
                            No:3, OAS Towers,<br>
                            Stringers Road, Vepery<br>
                            Chennai - 600 003.</li>
                        <li><i class="fa-solid fa-envelope" style="color: #9661C7;">&nbsp;&nbsp;</i> <a href="mailto:customercare@oasyspay.in">customercare@oasyspay.in</a></li>
                        <li><i class="fas fa-phone-volume" style="color: #9661C7;">&nbsp;&nbsp;</i> <a href="tel:1800 572 2770">1800 572 2770</a></li>
                        <!-- <li><span>Fax:</span> <a href="tel:+12129876543">+1-212-9876543</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Certifications</h3>
                    <div class="logo">
                        <p><img src="assets/img/iso_logo.png" alt="logo"></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="copyright-area">
            <p>© Klob is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div> -->
    </div>
    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>

<footer
    class="ctp-footer-area pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <!-- <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    <ul class="social-links">
                        <span>Find us on social media</span>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Products & Services</h3>
                    <ul class="links">
                        <li><a routerLink="/money-transfer">Money Transfer</a></li>
                        <li><a routerLink="/aeps">AEPS</a></li>
                        <li><a routerLink="/prepaid-dth-recharge">Prepaid and DTH Recharge</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Support</h3>
                    <ul class="links">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li>
                            <a routerLink="/terms-conditions">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/privacy-policy">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact">Contact Us</a>
                        </li>
                        <li><a routerLink="/about-us">About Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Need help?</h3>
                    <ul class="info">
                        <li>
                            <span>Location: </span>
                            OASYS One Stop Solution Pvt Ltd,<br>
                            No:3, OAS Towers,<br>
                            Stringers Road, Vepery<br>
                            Chennai - 600 003.</li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:customercare@oasyspay.in">customercare@oasyspay.in</a>
                        </li>
                        <li>
                            <span>Phone: </span>
                            <a href="tel:1800 572 2770">1800 572 2770</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Certifications</h3>
                    <div class="logo">
                        <p><img src="assets/img/iso_logo.png" alt="logo"></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="copyright-area">
            <p>© Klob is proudly created by <a href="https://envytheme.com/" target="_blank"> EnvyTheme</a></p>
        </div> -->
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>