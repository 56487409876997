import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-grievance-complaints',
    templateUrl: './grievance-complaints.component.html',
    styleUrls: ['./grievance-complaints.component.scss']
})
export class GrievanceAndComplaintsComponent implements OnInit {

    constructor(
        public router: Router
    ) { }

    ngOnInit() {}

}