<!-- Start Page Title Area -->
<div class="page-title-area item-bg-banking jarallax" >
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start AEPS Services Area -->
<div class="services-area ptb-70" #focusAeps>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">AEPS?</h1>
                    <div class="bar"></div>
                    <p>Aadhaar-enabled Payment System (AePS) is a revolutionary banking service in India that leverages the Aadhaar infrastructure to facilitate secure and convenient financial transactions for individuals across the country. AePS allows users to access basic banking services using their Aadhaar number and biometric authentication, providing a simple and inclusive way to conduct financial transactions.</p>
                    <p>AePS enables a range of banking transactions, including cash withdrawals, deposits, balance enquiries, fund transfers, and more. Instead of relying on traditional methods like ATM cards or PINs, AePS utilizes biometric authentication, such as fingerprint or iris scans, to verify users' identities securely. </p>
                    <!-- <p>Customers simply need to tell their 12-digit Aadhaar number or VID (virtual ID) which is link to their respective Bank.</p> -->
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/products-services/aeps.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End AEPS Services Area -->

<!-- Start Micro ATM Services Area -->
<div class="services-area ptb-70 bg-f7fafd" #focusMatm>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/Micro-atm.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">Micro ATM</h1>
                    <div class="bar"></div>
                    <p>Micro ATMs are portable banking devices designed to bring basic financial services to areas lacking traditional banking infrastructure, particularly in rural and remote regions. These handheld terminals function similarly to traditional ATMs, allowing users to perform essential banking transactions such as cash withdrawals, deposits, fund transfers, balance and inquiries.</p>
                    <p>Micro ATMs are operated by OASYS PAY agents who bring banking services directly to communities. These devices serve as a vital tool for financial inclusion, empowering individuals who may not have access to traditional banking channels to participate in formal financial systems.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Micro ATM Services Area -->

<!-- Start Money Transfer Services Area -->
<div class="services-area ptb-70" #focusDmt>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">Money Transfer</h1>
                    <div class="bar"></div>
                    <p>Domestic money transfer services facilitate the transfer of funds within the same country, typically between individuals or businesses. These transfers can be conducted through OASYS PAY. Money transfer services now offer online platforms and mobile apps, making it easier than ever for people to send and receive money anytime, anywhere.</p>
                    <!-- <p>To address this, OASYS PAY integrates with major banks in India and leverages its platform to empower it’s retailers to provide domestic money remittance or cash deposit service.</p>
                    <p>Our secure, instant and simple domestic service allow you to send money to any bank account in India.</p> -->
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/money-transfer.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Money Transfer Services Area -->

<!-- Start Axis CASA Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/axis-casa.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 80% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content text-justify">
                <div class="content">
                    <h1 class="font-wt-700">Axis CASA</h1>
                    <div class="bar"></div>
                    <p>CASA accounts are a fundamental part of banking services, offering customers a combination of current accounts for regular transactions and savings accounts for accumulating funds with interest.</p>
                    <p>Current accounts within Axis CASA cater primarily to businesses, corporates, and individuals with high transaction volumes. These accounts provide facilities such as cheque books, overdraft facilities, and online banking services, allowing account holders to manage their day-to-day financial transactions efficiently.</p>
                    <p>Savings accounts offered under Axis CASA are designed for individuals to save and grow their money while providing easy access to funds. These accounts typically offer interest on the deposited amount, along with features like ATM/debit cards, net banking, and mobile banking for convenient transactions.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Axis CASA Services Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->