<footer
    class="ctp-footer-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <!-- <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    <ul class="social-links">
                        <span>Find us on social media</span>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Products & Services</h3>
                    <ul class="links">
                        <li><a routerLink="/money-transfer">Money Transfer</a></li>
                        <li><a routerLink="/aeps">AEPS</a></li>
                        <li><a routerLink="/prepaid-dth-recharge">Prepaid and DTH Recharge</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Support</h3>
                    <ul class="links">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li>
                            <a routerLink="/terms-conditions">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/privacy-policy">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact">Contact Us</a>
                        </li>
                        <li><a routerLink="/about-us">About Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Need help?</h3>
                    <ul class="info">
                        <li>
                            <span>Location: </span>
                            OASYS One Stop Solution Pvt Ltd,<br>
                            No:3, OAS Towers,<br>
                            Stringers Road, Vepery<br>
                            Chennai - 600 003.</li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:customercare@oasyspay.in">customercare@oasyspay.in</a>
                        </li>
                        <li>
                            <i class="fas fa-phone"></i>
                            <a href="tel:1800 572 2770">1800 572 2770</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Certifications</h3>
                    <div class="logo">
                        <p><img src="assets/img/iso_logo.png" alt="logo"></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="copyright-area">
            <p>© Klob is proudly created by <a href="https://envytheme.com/" target="_blank"> EnvyTheme</a></p>
        </div> -->
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>