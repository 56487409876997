<!-- Start Page Title Area -->
<div class="page-title-area item-bg-aboutus jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Banner Area -->
<div class="banner-section" style="padding-top: 100px;">
    <div class="container">
        <div class="section-title">
            <h2>OASYS PAY</h2>
            <div class="bar"></div>
        </div>
        <div class="row m-0">
            <div class="col-lg-12 col-md-12 p-0">
                <div class="hero-content-aeps text-justify">
                    <!-- <h1 style="text-align: center;">OASYS PAY</h1> -->
                    <p>“OASYS PAY” services are providing by Oasys One Stop Solution Private Limited company which is a part of OASYS group of companies. The vision of the company is to provide all type of Digital and Cashless services to rural and remote Indian population through our BC network at their door step. We are currently having 2 Lakh+ “OASYS PAY” Branded BC Outlets across India and going forwards to our target of opening atleast 1 outlet per village for all the citizens to experience the easiest, fastest & safest Digital Payment Platform and Services under one roof.</p>
                    <p>OASYS PAY has played a vital role since inception, for empowerment of the unbanked and under-banked segments in India through offering Safe, Secure and Convenient payments solutions. This in turn has given them the freedom to select an instrument of their choice, be it from financial, digital or social perspective.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start About Area -->

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">Our Vision</h1>
                    <div class="bar"></div>
                    <p>We are embarking upon an inspired journey to empower rural parts of the nation including them into Digital transaction economy. We endeavor to bridge the gap in the digital divide.</p>
                    <p>We will bring convergent financial & digital products accessible at the door steps of the rural parts of the nation. We build platform with self-sustainable business models where it emerges rural entrepreneurs with commitment towards all its stakeholders.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/Vission.png" alt="image" style="max-width:80% !important;">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 80% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/Mission.png" alt="image" style="max-width:70% !important;">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 70% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">Our Mission</h1>
                    <div class="bar"></div>
                    <p>To serve a huge population in India, especially Rural India, is still not a part of digital economy and banking system. Due to digital illiteracy in key mass population who are still not self-serviced for basic banking and financial needs. We are trying to bridge this digital gap across India but mainly Rural and Semi Urban India to offer digital financial and non-financial services via our assisted outlets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->

<!-- Start partners Area -->
<app-partner></app-partner>
<!-- End partners Area -->

<!-- Start Team Area -->
<!-- <div class="team-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Meet our team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>John Capabel</h3>
                        <span>Programer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Team Area -->

<!-- Start Ready To Talk Area -->
<!-- <div class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about Klob</p>
            <a routerLink="/" class="btn btn-primary">Contact Us</a>
            <span><a routerLink="/">Or, get started now with a free trial</a></span>
        </div>
    </div>
</div> -->
<!-- End Ready To Talk Area -->

<!-- <app-partner></app-partner> -->

<!-- Start App Download Area -->
<!-- <div class="app-download-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <div class="main-image">
                        <img src="assets/img/mobile-app1.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="image">
                        <img src="assets/img/mobile-app2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.9s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/main-mobile.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <h2>You can find all the thing you need to payout</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End App Download Area -->

<!-- Start Account Create Area -->
<!-- <div class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your Klob account today!</p>
            <a routerLink="/" class="btn btn-primary">Get Your Klob Account</a>
        </div>
    </div>
</div> -->
<!-- End Account Create Area -->