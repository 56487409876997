<!-- Start Page Title Area -->
<div class="page-title-area item-bg-moneytransfer jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Money Transfer</h2>
            <p>OASYS PAY Money Transfer Service allows hassle free sending money to <br/> 400+ banks in India through it's wide agent network</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Banner Area -->
<div class="banner-section" style="padding-top: 80px;">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="hero-content">
                    <h1>Domestic Money Transfer Business | Tatkal Remittance Online</h1>
                    <p style="max-width: 515px;">We recognize the challenges that a common man faces to send money to their family and friends. Customers don’t need to travel long distances to go to bank branches, stand in long queues, and fill complicated forms to avail basic banking
                        services.</p>
                        <p style="max-width: 515px;">To address this, OASYS PAY integrates with major banks in India and leverages its platform to empower it’s retailers to provide domestic money remittance or cash deposit service.</p>
                    <p style="max-width: 515px;">Our secure, instant and simple domestic service allow you to send money to any bank account in India.</p>
                    <!-- <a routerLink="/" class="btn btn-primary">Create your account</a> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i> Play Video</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Information Area -->
<!-- <div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3>
                    <p>Free account available</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>10 minutes</h3>
                    <p>Apply for account</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>$100,000</h3>
                    <p>Guarantee on deposits</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Information Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Online Money Transfer Agency</h2>
                    <div class="bar"></div>
                    <p>Open your own online money transfer franchise with OASYS PAY and send money to bank account instantly through our highly secured money transfer business software and earn attractive commission.</p>
                    <p>We have more than 2 lakhs outlet in PAN India who are successfully working with us and are earning good.</p>
                    <p>Setup for this business is very easy, you just need one shop of any size and have OASYS PAY agent portal. Collect the money from the sender in cash, insert beneficiary’s account details, enter the amount and press send button and get
                        commission in every successful transaction. </p>
                    <p>Also it is very simple to top-up your OASYS PAY portal with debit card or credit card.</p>
                    <h2>How to Use?</h2>
                    <div class="bar"></div>
                    <p>Domestic money remittance as easy as mobile recharge you just need to collet the customer's mobile no, Beneficiary's bank account details and the amount to be transferred to the customer.</p>
                    <p>Complete the transaction by entering details at OASYS web portal or our android mobile app. Beneficiary's account will be credited instantly and the customer will also be notified via SMS with the details of the transaction in real time.</p>
                    <!-- <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul> -->
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Use of Oasys DMT Service</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Send money instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Available 24/7</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Safe and Secure transaction</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Verified account transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Instant confirmation to sender by SMS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> This service is available at all branches</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Transfers may occur on Sundays and public holidays also</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Benifits</h2>
                    <div class="bar"></div>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Cost-Effective</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Convenient way for transact</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Send money to all PSUs and private banks</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Free SMS notification for all transactions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Start providing money transfer service via OASYS PAY to your customers and increase your income.</span></li>
                        <!-- <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li> -->
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End Services Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->