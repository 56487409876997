import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-utility-bill',
    templateUrl: './utility-bill.component.html',
    styleUrls: ['./utility-bill.component.scss']
})
export class UtilityBillComponent implements OnInit {

    @ViewChild('focusBbps')focusBbps: ElementRef;

    services: string;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        this.services = localStorage.getItem('services');   
    }

    ngAfterViewInit() {
        if (this.services == 'bbps') {
            this.focusBbps.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        } 
    }

    ngOnDestroy() {
        localStorage.setItem('services', '');
    }

}