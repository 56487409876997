<!-- Start Page Title Area -->
<div class="page-title-area item-bg-grievance jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container text-justify">
        <div class="section-title">
            <h2>GRIEVANCE REDRESSAL POLICY</h2>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <p>We at OASYS value the trust placed upon us and strive to offer the best experience to you. Our
                        Grievance policy has been designed to resolve customer grievances in a timely and efficient
                        manner, while treating our customers fairly and courteously.</p>
                    <p>Our endeavour is to provide you with a seamless experience while transacting via Site or App or
                        any other OASYS Platform and when required, our in-app customer redressal support process, is
                        able to address all your concerns. In rare circumstances where, owning to various human, network
                        or system errors, some issues arise requiring a more focused and customized redressal, You may
                        refer to the following redressal mechanism in the sequential order.</p>
                    <h4 class="sub-headings">Grievance Redressal</h4>
                    <p>We have formulated an escalation matrix to ensure that Your Concerns are routes and addressed by
                        the right team. You are requested to read and understand the below escalation matrix to ensure a
                        timely redressal of Your concern.</p>
                    <p>We recommend You to carefully go through our help pages that provide self-help to our customers.
                        For queries and concerns that are not addressed through the help pages. You can contact our
                        customer support team through phone, email or through the Site.</p>
                    <p>Our in-app support channel is able to resolve a vast majority of customer complaints. In any
                        uncommon scenario, where You wish to escalate the concern to next level, You need to ensure that
                        You have already exhausted the previous level(s) and Your concern remains un-resolved or not
                        resolved to Your satisfaction</p>

                </div>

                <div class="comparisons-area ptb-40">
                    <div class="container">
                        <div class="comparisons-table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="width: 12% !important;" scope="col">Type</th>
                                        <th scope="col">Name & Contact Details</th>
                                        <th scope="col">Complaints Registration</th>
                                        <th scope="col">Complaints Resolution</th>
                                    </tr>
                                </thead>
                                <tbody class="table-text">
                                    <tr>
                                        <td>Level 1</td>
                                        <td>OASYS PAY customer support</td>
                                        <td>
                                            <p>You can register your complaint with OASYS PAY Customer Support by
                                                clicking on the support option which is available in our OSS App or Web.
                                                This service is available 24 hours a day and 7 days a week.
                                            </p>
                                        </td>
                                        <td>
                                            <p>a. We are committed to providing you with our first response within 24
                                                hours of receiving the complaint. We aim to resolve all Your
                                                complaints /queries within 3 business days.</p>
                                            <p>b. Resolution of Your complaint may get delayed due to operational or
                                                technical reasons. In such a scenario, You will be proactively informed
                                                of the timelines during which Your complaint will be addressed. In a few
                                                scenarios, while the resolution to a complaint is instant, it may take
                                                time for it to get reflected in the system.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level 2</td>
                                        <td>Send an email to customercare@oasyspay.in or Call on 1800-572-2770</td>
                                        <td>
                                            <p>You can access any of the following channels to escalate Your concerns if
                                                they have not been satisfactorily resolved at Level 1. We offer customer
                                                support service Monday to Friday (10:00 AM to 6:00 PM).</p>
                                        </td>
                                        <td>
                                            <p>a. We are committed to providing a first response within 24 hours of
                                                receiving the complaint.</p>
                                            <p>b. We aim to resolve all Level 4 complaints within 2 business days. Any
                                                delay in the resolution time shall be proactively communicated to You.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level 3</td>
                                        <td>
                                            <p><strong>Shipra Srivastava</strong><br />
                                                OASYS Cybernetics Private Limited,<br />
                                                OAS Towers, No.3, Stringers Road,<br />
                                                Periyamet, Vepery,<br />
                                                Chennai – 600003,<br />
                                                Tamil Nadu<br />
                                                <strong>Email : </strong>
                                                <a style="color: black;"
                                                    href="complaint.uppcl@oasyspay.in">complaint@oasyspay.in</a>
                                                <br />
                                                <strong>Time : </strong>Monday to Friday (10:00 AM to 6:00 PM)

                                            </p>
                                        </td>
                                        <td>
                                            <p><strong>Any Level 3 escalation email should contain the following
                                                    information:</strong>
                                            </p>
                                            <p>a. User’s name</p>
                                            <p>b. Registered contact number</p>
                                            <p>c. Ticket number or ID of the complaint registered at Level 1</p>
                                            <p>d. Details of why the resolutions provided at previous levels were deemed
                                                unsatisfactory</p>
                                        </td>
                                        <td>
                                            <p>a. We are committed to providing a first response within 24 hours of
                                                receiving the complaint.</p>
                                            <p>b. We aim to resolve all Level 3 complaints within 3 business days. Any
                                                delay in the resolution time shall be proactively communicated to You
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level 4</td>
                                        <td>
                                            <p><strong>Ashish Yadav</strong><br />
                                                OASYS Cybernetics Private Limited,<br />
                                                OAS Towers, No.3, Stringers Road,<br />
                                                Periyamet, Vepery,<br />
                                                Chennai – 600003,<br />
                                                Tamil Nadu<br />
                                                <strong>Email : </strong>
                                                grievanceofficer@oasyspay.in<br />
                                                <strong>Time : </strong>Monday to Friday (10:00 AM to 6:00 PM)
                                            </p>
                                        </td>
                                        <td>
                                            <p><strong>Any Level 3 escalation email should contain the following
                                                    information:</strong>
                                            </p>
                                            <p>a. User’s name</p>
                                            <p>b. Registered contact </p>
                                            <p>c. Ticket number of the complaint registered by Level 1</p>
                                            <p>d. Details of why the resolutions provided at previous levels were deemed
                                                unsatisfactory
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                a. We are committed to providing a first response within 24 hours of
                                                receiving the complaint.
                                            </p>
                                            <p>
                                                b. We aim to resolve all Level 4 complaints within 3 business days. Any
                                                delay in the resolution time shall be proactively communicated to You
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>