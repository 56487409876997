<!-- Start Main Banner Area -->

<div class="home-area">
    <div class="container">
        <div class="home-slides owl-carousel owl-theme">
            <div class="banner-item" style="margin-left:50px;">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h3>Digital Door Delivery -<br/> Last Mile Linkage</h3>
                            <!-- <p>Get the financial tools and insights to start, build, and grow your business.</p> -->
                            <br/>
                            <!-- <p>Join Hands with OASYS PAY and Earn Upto Rs.
                                50,000 Per Month</p>
                            <a routerLink="/" class="btn btn-primary">Join now</a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/delivery3.png" style="max-width: 90% !important;" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item" style="margin-left:50px;">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h3>Aadhaar Enabled Payment System</h3>
                            <p>OASYS PAY AEPS service enables our customers for hassle <br> free and secure balance
                                enquiry & cash withdraw from <br> their aadhaar linked bank accounts.</p>
                            <br/>
                            <!-- <p>Join Hands with OASYS PAY and Earn Upto Rs.
                                50,000 Per Month</p>
                            <a routerLink="/" class="btn btn-primary">Join now</a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/products-services/aeps.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item" style="margin-left:50px;">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h3>Money Transfer</h3>
                            <p>OASYS PAY Money Transfer Service allows hassle free sending money to 400+ banks
                                in India through it's wide agent network.</p>
                            <br/>
                            <!-- <p>Join Hands with OASYS PAY and Earn Upto Rs.
                                50,000 Per Month</p>
                            <a routerLink="/" class="btn btn-primary">Join now</a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/products-services/money-transfer.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item" style="margin-left:50px;">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h3>Bharat Bill Payment Service</h3>
                            <p>Oasys has created a strong business source for our <br>agents, allowing them to become a
                                one-stop-solution <br> for all of the customer’s recharge and bill payment needs. </p>
                            <br/>
                            <!-- <p>Join Hands with OASYS PAY and Earn Upto Rs.
                                50,000 Per Month</p>
                            <a routerLink="/" class="btn btn-primary">Join now</a> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/products-services/BBPS.png" alt="banner-image">
                        </div>
                    </div>
                </div>
               
            </div>
            
        </div>
    </div>
    <!-- <br/>
    <br/>
    <div class="container">
        <div><div class="row"><span style="text-align: center;">Join Hands with OASYS PAY and Earn Upto Rs.
            50,000 Per Month</span></div></div>
    </div> -->
</div>
<!-- End Main Banner Area -->

<!-- <div class="featured-boxes-area-join">
    <div class="container">
        <div class="featured-boxes-inner-join">
            <div class="row m-0">
                <div class="col-lg-12 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box-join">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>
                        <h3>Transparent Pricing</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn-join">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Start Featured Boxes Area -->
<div class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <img src="assets/img/icons/aeps.png" width="90" height="90"
                                                        class="text-center">
                        </div>
                        <h3>AEPS</h3>
                        <p>Perform your banking operation with your finger impression</p>
                        <a routerLink="/banking-services" (click)="readMore('aeps')" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <img src="assets/img/icons/money-transfer3.png" width="70" height="70"
                                                        class="text-center">
                        </div>
                        <h3>Money Transfer</h3>
                        <p>The fastest and easiest way of
                            money transfer</p>
                        <a routerLink="/banking-services" (click)="readMore('dmt')" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <img src="assets/img/icons/matm2.png" width="70" height="70"
                                                        class="text-center">
                        </div>
                        <h3>MicroATM</h3>
                        <p>Withdraw cash or Check Balance using your Debit card</p>
                        <a routerLink="/banking-services" (click)="readMore('matm')" class="read-more-btn">Read More</a>
                    </div>
                </div>
                
                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <img src="assets/img/icons/bbps2.png" width="70" height="70"
                                                        class="text-center">
                        </div>
                        <h3>Bharat Bill Payment</h3>
                        <p>Now fetch and pay utility bills with few clicks</p>
                        <a routerLink="/utility-bill-payment" (click)="readMore('bbps')" class="read-more-btn">Read More</a>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <img src="assets/img/mob-icons/insurance.webp" width="70" height="70"
                                                        class="text-center">
                        </div>
                        <h3>Insurance</h3>
                        <p>Get Insurance plan for assurance of your loved one's future</p>
                        <a routerLink="/insurance" (click)="readMore('insurance')" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <img src="assets/img/mob-icons/crm.png" width="70" height="70"
                                                        class="text-center">
                        </div>
                        <h3>Axis CRM</h3>
                        <p>Get all loans instantly under one roof</p>
                        <a routerLink="/credit-services" (click)="readMore('crm')" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Featured Boxes Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Our Presence and Customer Satisfaction</h2>
                    <div class="bar"></div>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                    <ul class="services-list-home">
                        <li><span><img src="assets/img/icons/india-color.png" alt="image" 
                            style="max-width: 20% !important; margin: 0px 5px 0px -15px;"> 22 States Covered</span></li>
                        <li><span><img src="assets/img/icons/people.png" alt="image" 
                            style="max-width: 20% !important; margin: 0px 5px 0px -15px;"> 21 cr+ Beneficiaries</span></li>
                        <li><span><img src="assets/img/icons/wholesaler.png" alt="image" 
                            style="max-width: 20% !important; margin: 0px 5px 0px -15px;"> 2 +Lakhs Outlets</span></li>
                        <li><span><img src="assets/img/icons/received.png" alt="image" 
                            style="max-width: 20% !important; margin: 0px 5px 0px -15px;"> 10+ Products & Services</span></li>
                        <li><span><img src="assets/img/icons/location.png" alt="image" 
                            style="max-width: 20% !important; margin: 0px 5px 0px -15px;">3 +Lakhs Total village Covered</span></li>
                        
                        
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/map3.png" alt="image" style="max-width: 75% !important; padding-left: 50px;">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 65% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/outlet.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/outlet-circle1.png" 
                        style="width: 450px !important; height: 470px !important;"
                        alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>One Stop Solutions Offerings</h2>
                    <div class="bar"></div>
                    <h4>Becoming an OSS Powered agent and offer digital financial services from your Outlet.</h4>
                    <ul class="services-list" style="margin-right: -60px;">
                        <li><span><i class="flaticon-check-mark"></i> One Stores, Many Service</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Earn More Income</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Get More Customers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Become Digital India Partner</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Uninterrupted Service</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Serving Rural Customer</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Trust & Loyalty</span></li>
                        <li><span><i class="flaticon-check-mark"></i> High earning Potential</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Comparisons Area -->
<!-- <div class="comparisons-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Compare us with others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Level 1</td>
                        <td>OASYS PAY customer support</td>
                        <td><p>You can register your complaint with OASYS PAY Customer Support by clicking on the support option which is available in our OSS App or Web.
                            This service is available 24 hours a day and 7 days a week.
                            </p></td>
                        <td><p>a. We are committed to providing you with our first response within 24 hours of receiving the complaint. We aim to resolve all Your complaints/queries within 3 business days.</p>
                        <p>b. Resolution of Your complaint may get delayed due to operational or technical reasons. In such a scenario, You will be proactively informed of the timelines during which Your complaint will be addressed. In a few scenarios, while the resolution to a complaint </p></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->
<!-- End Comparisons Area -->

<!-- Start Features Area -->
<div class="features-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Mobile App</h2>
            <div class="bar"></div>
            <h5>with uninterrupt services</h5>
        </div>
        <div class="row m-t-20">
            <div class="col-lg-4 col-md-4 text-right wow fadeInLeft d-none d-md-block">

                <!-- Aadhar Services -->
                <div class="row" style="margin-top: -15px;">

                    <div class="col-md-9 col-lg-10 col-8 m-t-30 text-right ">
                        <!-- <p class=" Pres-text f-25">AEPS</p> -->
                        <span class="mobile-app-fonts">AEPS</span>
                    </div>
                    <div class="col-md-3 col-lg-2 col-4 text-right m-t-20">
                        <img src="assets/img/mob-icons/aeps.png" alt="" width="57" height="57" style="max-width: 160% !important;">
                    </div>

                </div>
                <p class="text-right" style="width: 102%;">Customers can check their account balance and withdraw
                    their money just by their finger tip. <br/>
                    <a routerLink="/banking-services" (click)="readMore('aeps')"><span class="font-wt-600">Know more...</span></a></p>
                <!-- Bill Payment -->
                <div class="row">
                    <div class="col-md-9 col-lg-10 col-8 m-t-30 text-right">
                        <!-- <p class=" Pres-text f-25">Bill Payment</p> -->
                        <span class="mobile-app-fonts">Bill Payment</span>
                    </div>
                    <div class="col-md-3 col-lg-2 text-right m-t-20">
                        <img src="assets/img/icons/bbps2.png" alt="" width="50" height="50" style="max-width: 160% !important;">
                    </div>
                </div>
                <p>One-stop-solution for all customers’ recharge and bill payment needs. Pay all their bills at one
                    place including Electricity, Water, Gas, DTH, Broadband, and Mobile through one app. <br/>
                    <a routerLink="/utility-bill-payment" (click)="readMore('bbps')"><span class="font-wt-600">Know more...</span></a></p>

                <!-- Secure Payments -->
                <div class="row">
                    <div class="col-md-9 col-lg-10 col-8 m-t-30 text-right">
                        <!-- <p class=" Pres-text f-25">Secure Payments</p> -->
                        <span class="mobile-app-fonts">Secure Payments</span>
                    </div>
                    <div class="col-md-3 col-lg-2 col-4 text-right m-t-20">
                        <img src="assets/img/mob-icons/secure-payments.png" alt="" width="57" height="57" style="max-width: 160% !important;">
                    </div>
                </div>
                <p>Agent can accept payments using either customer’s debit card or a QR which can be scanned by an
                    Android Smartphone. The payment methods are extremely efficient and secure. <br/>
                    <a href="#"><span class="font-wt-600">Know more...</span></a></p>


            </div>
            
            <div class="col-lg-4 col-md-4 about-img text-center">
                <img src="assets/img/mob-icons/mob-app1.png" alt="" style="max-width: 80% !important;">
            </div>
            <div class="col-lg-4 col-md-4 text-left wow fadeInRight mobappRgt">

                <!-- Money Transfer -->
                <div class="row">
                    <div class=" col-lg-2 col-md-3 col-2">
                        <img src="assets/img/mob-icons/money-transfer.png" alt="" width="57" height="57" style="max-width: 160% !important;">
                    </div>
                    <div class="col-lg-10 col-md-9 col-10 m-t-10">
                        <!-- <p class=" Pres-text f-25 text-left">Money Transfer</p> -->
                        <span class="mobile-app-fonts">Money Transfer</span>
                    </div>

                </div>
                <p class="text-left m-b-20">OASYS PAY has a robust transfer in NEFT and IMPS facility, using which
                    our agents can help customers remit funds to a wide array of banks or locations in real-time to
                    the beneficiaries. <br/>
                    <a routerLink="/banking-services" (click)="readMore('dmt')"><span class="font-wt-600">Know more...</span></a></p>


                <!-- View Statements -->
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-2">
                        <img src="assets/img/mob-icons/balance.png" alt="" width="57" height="57" style="max-width: 160% !important;">
                    </div>
                    <div class="col-lg-10 col-md-9 col-10 m-t-10">
                        <!-- <p class=" Pres-text f-25 text-left">View Statements</p> -->
                        <span class="mobile-app-fonts">View Statements</span>
                    </div>

                </div>
                <p class="text-left m-b-30">Agents can view their payment statement and transaction history through his
                    OASYS PAY mobile app. <br/>
                    <a routerLink="/banking-services"><span class="font-wt-600">Know more...</span></a></p>



                <!-- Insurance -->
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-2">
                        <img src="assets/img/mob-icons/insurance.webp" alt="" width="57" height="57" style="max-width: 160% !important;">

                    </div>
                    <div class="col-lg-10 col-md-9 col-10 m-t-10">
                        <!-- <p class=" Pres-text f-25 text-left">Insurance</p> -->
                        <span class="mobile-app-fonts">Insurance</span>
                    </div>
                </div>
                <p class="text-left">Availability of insurance policies immediately from OASYS PAY mobile
                    app for 2 wheeler, 4 wheeler and health. <br/>
                    <a routerLink="/insurance"><span class="font-wt-600">Know more...</span></a></p>

            </div>
        </div>

        <div class="row m-t-20">
            <div class="section-title">
                <div class="row m-t-20">
                    <h4>To Download Our app, Please Click below</h4>
                </div>
                <div class="btn-box">
                    <a href="https://play.google.com/store/apps/details?id=com.oss.mapp&pcampaignid=web_share" class="app-store-btn" target="_blank">
                        <i class="flaticon-play-store"></i>
                        Download on
                        <span>Google play</span>
                    </a>
                </div>
                
            </div>
        </div>

    </div>
</div>
<!-- End Features Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-talk-button></app-talk-button> -->

<app-partner></app-partner>

<!-- <app-download></app-download> -->

<!-- Start Account Create Area -->
<!-- <app-account-button></app-account-button> -->
<!-- End Account Create Area -->