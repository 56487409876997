import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';

import { HomeComponent } from './home/home.component';
import { TrainingComponent } from './gallery/training/training.component';
import { TermsAndConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GrievanceAndComplaintsComponent } from './grievance-complaints/grievance-complaints.component';
import { AEPSComponent } from './products-services/aeps/aeps.component';
import { MoneyTransferComponent } from './products-services/money-transfer/money-transfer.component';
import { PrepaidDTHRechargeComponent } from './products-services/prepaid-dth-recharge/prepaid-dth-recharge.component';
import { BankingServicesComponent } from './products-services/banking-services/banking-services.component';
import { UtilityBillComponent } from './products-services/utility-bill/utility-bill.component';
import { GtoCServicesComponent } from './products-services/g2c-services/g2c-services.component';
import { InsuranceComponent } from './products-services/insurance/insurance.component';
import { CreditServicesComponent } from './products-services/credit-services/credit-services.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about-us', component: AboutComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'contact', component: ContactComponent},

    // Here add new pages component
    {path: 'training', component: TrainingComponent},
    {path: 'terms-conditions', component: TermsAndConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'grievance-complaints', component: GrievanceAndComplaintsComponent},
    {path: 'aeps', component: AEPSComponent},
    {path: 'money-transfer', component: MoneyTransferComponent},
    {path: 'prepaid-dth-recharge', component: PrepaidDTHRechargeComponent},
    {path: 'banking-services', component: BankingServicesComponent},
    {path: 'utility-bill-payment', component: UtilityBillComponent},
    {path: 'g2c-services', component: GtoCServicesComponent},
    {path: 'insurance', component: InsuranceComponent},
    {path: 'credit-services', component: CreditServicesComponent},
    
    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }