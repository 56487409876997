import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-banking-services',
    templateUrl: './banking-services.component.html',
    styleUrls: ['./banking-services.component.scss']
})
export class BankingServicesComponent implements OnInit {

    @ViewChild('focusMatm')focusMatm: ElementRef;
    @ViewChild('focusAeps')focusAeps: ElementRef;
    @ViewChild('focusDmt')focusDmt: ElementRef;
    
    services: string;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        this.services = localStorage.getItem('services');
    }

    ngAfterViewInit() {
        if (this.services == 'matm') {
            this.focusMatm.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        } else if (this.services == 'aeps') {
            this.focusAeps.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        } else if (this.services == 'dmt') {
            this.focusDmt.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        } 
    }

    ngOnDestroy() {
        localStorage.setItem('services', '');
    }

}