import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FunfactComponent } from './common/funfact/funfact.component';
import { AboutComponent } from './pages/about/about.component';
import { PartnerComponent } from './common/partner/partner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';

import { HomeComponent } from './home/home.component';
import { TrainingComponent } from './gallery/training/training.component';
import { TermsAndConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GrievanceAndComplaintsComponent } from './grievance-complaints/grievance-complaints.component';
import { AEPSComponent } from './products-services/aeps/aeps.component';
import { MoneyTransferComponent } from './products-services/money-transfer/money-transfer.component';
import { PrepaidDTHRechargeComponent } from './products-services/prepaid-dth-recharge/prepaid-dth-recharge.component';
import { FooterTwoComponent } from './footer-2/footer-2.component';

import { BankingServicesComponent } from './products-services/banking-services/banking-services.component';
import { UtilityBillComponent } from './products-services/utility-bill/utility-bill.component';
import { GtoCServicesComponent } from './products-services/g2c-services/g2c-services.component';
import { InsuranceComponent } from './products-services/insurance/insurance.component';
import { CreditServicesComponent } from './products-services/credit-services/credit-services.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FunfactComponent,
    AboutComponent,
    PartnerComponent,
    NotFoundComponent,
    FaqComponent,
    ContactComponent,
    HomeComponent,
    TrainingComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    GrievanceAndComplaintsComponent,
    AEPSComponent,
    MoneyTransferComponent,
    PrepaidDTHRechargeComponent,
    FooterTwoComponent,
    BankingServicesComponent,
    UtilityBillComponent,
    GtoCServicesComponent,
    InsuranceComponent,
    CreditServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
