<!-- Start Page Title Area -->
<div class="page-title-area item-bg-faq jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<div class="faq-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="faq-content">
                    <h2>Frequently Asked Questions</h2>
                    <div class="bar"></div>
                    <!-- <p>Have a question that has not been answered below? Feel free to send us an email at customercare@oasyspay.in and we shall pop the answer up here for all to see.</p> -->
                    <div class="faq-image">
                        <img src="assets/img/faq.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="row ">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" id="security-tab" data-bs-toggle="tab" href="#security" role="tab" aria-controls="security">General</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Service</a>
                            <ul class="dropdown-menu-faq">
                                <!-- banking services -->
                                  <li><a class="dropdown-item-faq" id="aeps-tab" data-bs-toggle="tab" href="#aeps" role="tab" aria-controls="aeps">AePS</a></li>
                                  <li><a class="dropdown-item-faq" id="matm-tab" data-bs-toggle="tab" href="#matm" role="tab" aria-controls="matm">Micro ATM</a></li>
                                  <!-- <li><a class="dropdown-item-faq" id="casa-tab" data-bs-toggle="tab" href="#casa" role="tab" aria-controls="casa">Axis CASA</a></li> -->
                                  <li><a class="dropdown-item-faq" id="dmt-tab" data-bs-toggle="tab" href="#dmt" role="tab" aria-controls="dmt">Money Transfer</a></li>

                                  <!-- utitlity -->
                                  <li><a class="dropdown-item-faq" id="bbps-tab" data-bs-toggle="tab" href="#bbps" role="tab" aria-controls="bbps">BBPS</a></li>
                                  <li><a class="dropdown-item-faq" id="dth-tab" data-bs-toggle="tab" href="#dth" role="tab" aria-controls="dth">DTH Recharge</a></li>
                                  <li><a class="dropdown-item-faq" id="mobile-tab" data-bs-toggle="tab" href="#mobile" role="tab" aria-controls="mobile">Mobile Recharge</a></li>

                                  <!-- insurance -->
                                  <li><a class="dropdown-item-faq" id="accidental-tab" data-bs-toggle="tab" href="#accidental" role="tab" aria-controls="accidental">Accidental</a></li>
                                  <li><a class="dropdown-item-faq" id="hospicash-tab" data-bs-toggle="tab" href="#hospicash" role="tab" aria-controls="hospicash">Hospicash</a></li>
                                </ul>
                        </li>
                    </ul>
                </div>
                
                <div class="tab-content tab-padding" id="myTabContent" >
                    <div class="tab-pane fade show active" id="security" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I need more information about OASYS PAY?
                                    </a>
                                    <div class="accordion-content">
                                        <p>OASYS PAY was established in 2019 with vision to provide all type of Digital and Cashless services to rural and remote Indian population through our BC network at their door step. We are a Department of Industrial Policy and Promotions (DIPP) certified start-up.
                                        </p>
                                       <p>We offer simple, secure, instant and hassle free G2C, B2C, BC & other value added services through our Business Correspondent network partnered with various Banks, Operators and Aggregators.</p>
                                       <p>For more information, you can visit our website 
                                        <a href="https://oasyspay.in/about-us/">https://oasyspay.in/about-us/
                                        </a></p>
                                    </div>
                                    
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the charges required to start a business with OASYS PAY?
                                    </a>
                                    <p class="accordion-content">You can start a business with OASYS PAY with select products like Recharges, Bill payments, G2C services that are completely free. We also have attractive packages that helps you start your digital & banking business. To further enhance your earnings, you can check all the package details on your mobile, POS & web APP.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        In case I face any problems, how do I get a solution for the same?
                                    </a>
                                    <p class="accordion-content">You can raise any query through our mobile app/web portal or email us at <a href="customercare@oasyspay.in">customercare@oasyspay.in</a> or alternatively you can call us on our customer care Toll Free number 1800 572 2770. Our customer support team is also available from 10 AM to 6 PM to assist you with queries. Customer support is available in many languages like Hindi, English, and Tamil.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        When and how will I receive my commission?
                                    </a>
                                    <p class="accordion-content">OASYS PAY commissions are credited to your wallet instantly, whereas other companies pay it after one day or monthly.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I transfer the balance from commission amount or trade wallet to Bank?
                                    </a>
                                    <p class="accordion-content">
                                        Yes. You can transfer the balance from your commission amount or trade wallet to Bank. Also you can transfer the balance from your commission amount to trade wallet so that you can use that balance for doing more transactions.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I do not want to share my PAN Card.
                                    </a>
                                    <p class="accordion-content">
                                        OASYS PAY is an ISO 27001 and DIPP certified fintech company. Your PAN Card information will be safe with us. PAN Card is mandatory for onboarding purposes and for other all financial transactions.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I keep a record of all my past transactions?
                                    </a>
                                    <p class="accordion-content">
                                        Yes. Your past transactions are available on the mobile app/web portal for your reference and download.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the minimum amount I need to upload in the wallet?
                                    </a>
                                    <p class="accordion-content">
                                        Minimum Rs. 100 can be uploaded in the wallet.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I create 2 retailer IDs with one document?
                                    </a>
                                    <p class="accordion-content">
                                        No. You cannot run 2 IDs simultaneously with the same documents. For security purposes, both the IDs will get blocked. If your IDs get blocked, you can send an email to <a href="customercare@oasyspay.in">customercare@oasyspay.in</a> along with details of the ID you want to continue with.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Who can unlock a retailer ID?
                                    </a>
                                    <p class="accordion-content">
                                        You can contact our customer care on 1800 572 2770 for assistance on this matter.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the modes of topping up Trade wallet for the Retailer?
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>Cash Deposit on Bank Branch</li>
                                            <li>Net-banking (Payment gateway)</li>
                                            <li>UPI (Payment Gateway)</li>
                                            <li>Request from Distributor</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Are there any charge for retailers to load the Trade wallet?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, there are some charges for retailers to load the main wallet in case if they choose Net-banking option to load the main Trade wallet.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I load my trade wallet if I have done ‘Cash deposit on Bank Branch’?
                                    </a>
                                    <div class="accordion-content">
                                        <p>You can follow these steps to load your trade wallet through Branch deposit:</p>
                                       
                                        <p>1. Go to <span class="font-wt-600">‘Add Money’ </span>option on app / web portal<br/>
                                           2. Then go to <span class="font-wt-600">‘Offline Wallet Recharge’ </span>option on app / web portal<br/>
                                            3. Get the deposit slip<br/>
                                            4. Fill the required details which are appearing on screen <br/>
                                            5. Upload the deposit slip received from your bank <br/>
                                            6. After submitting the slip, top up will be credited within 30 minutes.</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I load my trade wallet if I have done ‘Online Bank transfer’?
                                    </a>
                                    <div class="accordion-content">
                                        <p>You can follow these steps to load your trade wallet through online bank transfer:</p>
                                       
                                        <p>1. Log in to your Net banking portal or any payment application <br/>
                                           2. Go to the transfer option. <br/>
                                            3. Add OASYS PAY as beneficiary, enter Account number & IFSC code (You can find the OASYS PAY’s account details in the claim top-up option under banking rules)<br/>
                                            4. Enter the required amount and pay <br/>
                                            5. Take a screenshot of the successful transaction. <br/>
                                            6. Fill the required details which are appearing on screen <br/>
                                            6. Upload the screenshot of the transaction in the web portal / app under <span class="font-wt-600">Add Money -> Offline Wallet Recharge</span> option<br/>
                                            7. After uploading the screenshot, top up will be credited within 30 minutes</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I have attempted to load my wallet through Payment Gateway. However, the transaction status shows ‘In progress’. What should I do?
                                    </a>
                                    <div class="accordion-content">
                                        <p>We regret the inconvenience caused for an <span class="font-wt-600">‘in-progress’ </span>transaction, kindly wait for 72 working hours for the status update, if it’s a successful transaction, the amount will be credited to your main wallet.
                                        </p>
                                       <p>If the transaction has failed, the amount will be reversed to your source account.</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I have attempted to load my wallet through Payment Gateway. However, the transaction has failed and the amount is debited. What should I do?
                                    </a>
                                    <div class="accordion-content">
                                        <p>We regret the inconvenience caused due to a failed transaction, kindly wait for 72 working hours, your bank will settle the amount in your account.
                                        </p>
                                       <p>If the amount is not reversed in 72 hours, please raise a complaint from your ‘Complaint and Enquiry’ section with ‘updated passbook/bank statement’. We will revert soon.</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is ‘Wallet to Bank’ and how can I use it?
                                    </a>
                                    <p class="accordion-content">
                                        ‘Wallet to Bank’ refers to transferring your trade balance amount to your linked bank account which you have provided at the time to on-boarding. This option is available under ‘Payment Settings -> Wallet to Bank’ in mobile app and web portal.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the payment modes and charges to send money to my linked bank account?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Please find the charges, details and mode of payment details given below:</p>
                                        <ul class="article-features-list-faq">
                                            <li>Amount: ₹10 to ₹24,999; Charges: ₹5; Mode of payment: IMPS</li>
                                            <li>Amount: ₹25,001 to ₹99,999; Charges: ₹10; Mode of payment: IMPS</li>
                                            <li>Amount: (=) or Above ₹1 lakh; Charges:₹3; Mode of payment: NEFT</li>
                                        </ul>
                                        <p>If the transaction mode is NEFT there will be a flat charge of ₹3</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I have done a ‘Wallet to Bank’ transaction and the status is ‘In-progress’. What should I do?
                                    </a>
                                    <p class="accordion-content">
                                        Kindly wait for 48 bank working hours for the status to be updated. If the status is success, the amount will be credited to your bank account. If the credit has failed, the amount will be reversed to your trade wallet. In meantime, amount will be shown into <span class="font-wt-600">‘Reserved Amount’.</span>
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the limit for ‘Wallet to Bank’ transactions?
                                    </a>
                                    <p class="accordion-content">
                                        There is no transaction limit. Limit of ₹1 lakh is applicable only if Full KYC is incomplete.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I change my linked bank account?
                                    </a>
                                    <p class="accordion-content">
                                        You can change your linked bank account from ‘My Profile Settings’ section.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the age required to become Oasys Pay Distributor?
                                    </a>
                                    <p class="accordion-content">
                                        If you want to become a Distributor of Oasys Pay, then you must be over 18 years of age.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can a retailer become a distributor?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, a retailer can become a distributor. To register as a distributor please use a different mobile number and email ID.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I forgot my password. How can I access my account?
                                    </a>
                                    <div class="accordion-content">
                                        <p>To access the account, you can reset your password by clicking on the "Forgot Password" button that appears on the login page. Follow the below instruction to do the same;</p>
                                        <ul class="article-features-list-faq">
                                            <li>Step 1: Enter User ID</li>
                                            <li>Step 2: Click on “Submit” Button.</li>
                                            <li>Step 3: Enter your OTP, received on Agent registered mobile number</li>
                                            <li>Step 4: Enter your New Password</li>
                                            <li>Step 5: Retype / Confirm your New Password</li>
                                            <li>Step 6: Click on Submit Button</li>
                                        </ul>
                                        <p>Then you can login with updated credential</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How do I unlock my account?
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>If account is locked due to 5 consecutive wrong attempts then wait for 30 minutes and then login with correct password.</li>
                                            <li>If your account has been blocked by management, please contact our customer service team by call or e-mail.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I become a distributor for free?
                                    </a>
                                    <p class="accordion-content">
                                        No, you cannot become a distributor of Oasys Pay for free, for this, you have to deposit a certain amount*, after that, you will sign an agreement with the company, only then you can become a distributor of Oasys Pay.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Whether salary is paid to Oasys Pay Distributor?
                                    </a>
                                    <p class="accordion-content">
                                        No, the distributor of Oasys Pay is not paid any salary by Oasys Pay, their work is based on commission
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How will the Oasys Pay distributor transfer his wallet money to the bank?
                                    </a>
                                    <div class="accordion-content">
                                        <p>If the distributor has money in their wallet and he wants to send it to his bank then he can easily send it. There will be some charge which will be deducted from their wallet.</p>
                                        <div class="comparisons-table table-responsive">
                                            <table class="table">
                                            <thead>
                                                <tr><th colspan="2" style="text-align: center;">Fund Transfer from Wallet to Bank - IMPS Mode</th></tr>
                                                <tr>
                                                    <th>Slab (in Rs)</th>
                                                    <th>Charges deducted from Distributor's wallet</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1-24,999</td>
                                                    <td>Rs. 5 (inclusive of GST)</td>
                                                </tr>
                                                <tr>
                                                    <td>25,000-5,00,000</td>
                                                    <td>Rs. 10 (inclusive of GST)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <ul class="article-features-list-faq">
                                            <li>First of all, login with your ID and click on "Payment Settings" and select the option according to you.</li>
                                            <li>Verify the bank details then enter the amount and select IMPS in IMPS / NEFT in transfer mode if a fast transaction is needed. Then, click "Transfer".</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Why has my account been locked?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Your account may be closed for the following reasons;</p>
                                        <ul class="article-features-list-faq">
                                            <li>Due to 5 consecutive attempts to open the account with the wrong password.</li>
                                            <li>It may be blocked due to suspicious activities by the management.</li>
                                            <li>Due to non-transactional activity for the last 6 months.</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="aeps" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is AePS?
                                    </a>
                                    <p class="accordion-content fast">
                                        Aadhaar Enabled Payment System (AePS) is a payment service empowering a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled bank account and perform basic banking services using their Fingerprint / IRIS scan through a Business Correspondent.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What services can be availed using AePS?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Under AePS currently following services are present:</p>
                                        <ul class="article-features-list-faq">
                                            <li>Balance Enquiry</li>
                                            <li>Mini Statement</li>
                                            <li>Cash Withdrawal</li>
                                            <li>Cash Deposit</li>
                                            <li>Aadhaar Pay</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the benefits of Aadhaar services?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Benefits of AePS are as follow:</p>
                                        <ul class="article-features-list-faq">
                                            <li>As a BC, using AePS, you can provide ATM services in your area.</li>
                                            <li>Aadhaar enabled Payment System is easy to use, safe and secure payment platform to avail benefits by using Aadhaar number & biometrics/IRIS scan without the need for debit or credit card.</li>
                                            <li>Aadhaar enabled Payment System is based on the demographic and biometric/iris information of an individual, it eliminates the threat of any fraud and non-genuine activity.</li>
                                            <li>Aadhaar enabled Payment System facilitate disbursements of Government entitlements like NREGA, Social Security pension, Handicapped Old Age Pension etc. of any Central or State Government bodies using Aadhaar authentication.</li>
                                            <li>Aadhaar enabled Payment System facilitates inter-operability across banks in a safe and secured manner</li>
                                            <li>Reaching the unreached - The model enables us to extend financial services to the unreached customers beyond bank branch network as beneficiaries of the BCs are mostly located at unbanked and underbanked areas.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I start providing this service?
                                    </a>
                                    <div class="accordion-content">
                                        <p>To provide AePS service, you will require below mentioned things:</p>
                                        <ul class="article-features-list-faq">
                                            <li>A unique OASYS PAY agent ID</li>
                                            <li>STQC certified Fingerprint/ IRIS scan device like Mantra MFS100 etc.</li>
                                            <li>Mobile / POS / Laptop</li>
                                            <li>Network Connectivity</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do customers need to carry their Aadhaar card to avail this service?
                                    </a>
                                    <p class="accordion-content">
                                        To conduct an Aadhaar transaction, customer does not need to carry Aadhaar card. However, it is essential for customers to link their bank account with Aadhaar.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do I charge my customer for this service?
                                    </a>
                                    <p class="accordion-content">
                                        No. You should not any type of charge from customer for this service. Although you will receive commission for your transaction.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Which biometric devices can i use with OASYS PAY?
                                    </a>
                                    <p class="accordion-content">
                                        You can use STQC certified devices line Mantra, Morpho & Precision etc devices with OASYS PAY to provide AePS services.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        AePS transaction has failed, however amount is debited from customer’s bank account, what should I do?
                                    </a>
                                    <p class="accordion-content">
                                        In an unfortunate scenario, if a transaction is Failed & customer’s bank account is debited then no need to worry, customer’s money will be automatically reversed into their account within 7 working days. You can call our customer care 1800 572 2770 or check mini-statement to check the reversal.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Have completed AePS transaction, customer’s account is debited however my trade wallet is not credited, what should I do?
                                    </a>
                                    <p class="accordion-content">
                                        If the transaction is ‘Success’ & customer’s bank account is also debited then the amount will be credited into your trade wallet balance immediately. But it may sometimes take 1-2 hours for the credit to reflect in your trade wallet balance. Incase you do not receive the credit in stipulated time, please call our customer care on 1800 572 2770.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        When would I get the commission if transaction is successful?
                                    </a>
                                    <p class="accordion-content">
                                        If transaction is successful, then commission amount will be credited into your <span class="font-wt-600">‘Commission Amount’</span> in real time.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the AEPS withdrawal limits for the customer?
                                    </a>
                                    <div class="accordion-content">
                                        <p>The AEPS withdrawal limits for the customer are:</p>
                                        <ul class="article-features-list-faq">
                                            <li>Minimum amount of ₹100 per transaction & Maximum amount of ₹10,000 per transaction.</li>
                                            <li>Amount should be multiple of ₹50.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’Invalid agent/CSR code’</span> error mean, while doing an AePS transaction mean?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’Invalid agent/CSR code’</span> error occurs when a customer’s Aadhaar number is not responding to the associated fingerprint.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">‘BIOMETRIC DATA DID NOT MATCH’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’BIOMETRIC DATA DID NOT MATCH’ </span> error occurs when Customer's Finger print is not matched properly with UIDAI database. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’INVALID ACCOUNT / NO CHECKING ACCOUNT’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’INVALID ACCOUNT / NO CHECKING ACCOUNT’</span> error occurs when a customer’s Aadhaar Number is not linked to the customer’s bank a/c.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does  <span class="font-wt-600">‘ISSUER UN-AVAILABLE’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">‘ISSUER UN-AVAILABLE’</span> error occurs when Customer's Bank is not responding properly during the transaction. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’ISSUER CBS UN-AVAILABLE’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’ISSUER CBS UN-AVAILABLE’</span> error occurs when Customer's Bank is not responding properly during the transaction. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’INVALID TRANSACTION’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’INVALID TRANSACTION’</span> error occurs when Customer's Bank has declined the transaction. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’INSUFFICIENT FUNDS’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’INSUFFICIENT FUNDS’</span> error occurs when there is not sufficient fund available in Customer's Bank Account on which customer wants to withdraw.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’BIOMETRIC DATA DID NOT MATCH’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’BIOMETRIC DATA DID NOT MATCH’</span> error occurs when Customer's Finger print is not matched properly with UIDAI database. Please tell customer to either clean finger or put different finger.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’SUSPECTED FRAUD’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’SUSPECTED FRAUD’</span> error occurs when transaction falls under Risk and Fraud mechanism implemented by Bank. Thatswhy rejected by Bank to safeguard customer's interest. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’EXCEEDS WITHDRAWAL AMOUNT LIMIT’ or ‘TRANSACTION AMOUNT EXCEED LIMIT</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">‘EXCEEDS WITHDRAWAL AMOUNT LIMIT’ or ‘TRANSACTION AMOUNT EXCEED LIMIT’</span> error occurs when Customer has exceeded transaction amount limit as prescribed by his bank for the day/month.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’ISSUER IS INOPERATIVE’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <p class="accordion-content">
                                        <span class="font-wt-600">’ISSUER IS INOPERATIVE’</span> error occurs when Customer's Bank link is failure and not responding during the transaction. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’Invalid Mc Code Under Metatag’</span> error mean, while doing an AePS transaction?
                                    </a>
                                    <div class="accordion-content">
                                        <p>This error appears for both Morpho and Startek devices. Please follow the steps given below:</p>
                                        <ul class="article-features-list-faq">
                                            <li>For Morpho device: “Refresh” on “RD service application” at the top right corner and restart your device.</li>
                                            <li>For Startek device: Click on “Re-registered” at the RD application and restart your device</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Getting an error <span class="font-wt-600">’Error code-999’</span> doing AePS transaction, what should I do?
                                    </a>
                                    <div class="accordion-content">
                                        <p>
                                            If you get an <span class="font-wt-600">’Error code-999’</span> while doing AePS Cash Withdrawal, please check
                                            the below points: </p>
                                        <ul class="article-features-list-faq">
                                            <li>Go to your time and date setting and set the time zone to automatic.</li>
                                            <li>that your mobile phone is not rooted.</li>
                                            <li>Check is the customer’s finger print / IRIS scan is captured properly, if not try again.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What does <span class="font-wt-600">’Enter Activation Code’</span> error while connecting the biometric device mean?
                                    </a>
                                    <div class="accordion-content">
                                        <p>
                                            <span class="font-wt-600">’Enter Activation Code’</span> error is a Morpho biometric device issue that occurs when the connected device is not registered.</p>
                                        <p>Following is the solution:</p>
                                        <ul class="article-features-list-faq">
                                            <li>Connect a registered device.</li>
                                            <li>Contact Morpho device customer care (01204882100) and get the activation code.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Getting an error <span class="font-wt-600">’Invalid Time Stamp’</span> doing AePS transaction. What should I do?
                                    </a>
                                    <p class="accordion-content">
                                        If you get an <span class="font-wt-600">’Invalid Time Stamp’</span> error while doing AePS transaction, then go to your Time setting, set the time zone as “UTC (+5.30)”, restart the device and try again.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="bbps" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is BBPS?
                                    </a>
                                    <p class="accordion-content fast">
                                        Bharat Bill Payment System (BBPS) is a Reserve Bank of India (RBI) conceptualised system driven by National Payments Corporation of India (NPCI) that offers interoperable and accessible bill payment service to consumers via digital (bank channels) as well as through a network of agents & bank branches.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Why BBPS?
                                    </a>
                                    <div class="accordion-content">
                                            <p><span class="font-wt-600">Interoperable: </span>BBPS will be an integrated platform connecting banks and non-banks in bills aggregation business, billers, payment service providers and retail bill outlets.</p>
                                            <p><span class="font-wt-600">Accessible: </span>It will facilitate seamless payment of bills through any channel including Agent-Assisted Model at any Agent / BC outlet / bank branch / online channels.</p>
                                            <p><span class="font-wt-600">Integration: </span>BBPOU (Bharat Bill Payment Operating Unit) i.e Banks / Non-Banks will have to connect only to BBPCU (Bharat Bill Payment Central Unit) i.e NPCI (National Payment Corporation of India) to get access to all the billers. Billers need to connect to just one BBPOU to enable all customers to pay their bills.</p>
                                            <p><span class="font-wt-600">Clearing & Settlement: </span>Multiple clearing and guaranteed settlements between different parties with standardized TAT.</p>
                                            <p><span class="font-wt-600">Complaint Management: </span>BBPS will put in place standardized complaint management system to handle customer grievance for all kind of transactions.</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the benefits to customer?
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>Convenient and reliable service with high degree of Trust and Security.</li>
                                            <li>Transparent pricing with instant payment confirmation </li>
                                            <li>Unified Grievance Handling  </li>
                                            <li>Standard Rules</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What types of bills I can pay or services I can avail on OASYS PAY platform?
                                    </a>
                                    <p class="accordion-content">
                                        You can pay all recurring payments like Broadband Post-paid, Cable TV, Clubs and Associations, Credit Card Payment, DTH, Education Fees, Electricity, FASTag Recharge, Piped Gas, Hospitals, Hospital Pathology, Housing Society, Insurance, Landline Post-paid, Loan Repayment, LPG Gas, Metro Recharge, Mobile Post-paid, Municipal Taxes, Municipal Services, Mutual Funds, Deposit, Subscription, Water, Rental, Mobile Prepaid, Health Insurance.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is Fetch Bill option?
                                    </a>
                                    <p class="accordion-content">
                                        For making payment of your utility Bill, there is no need to enter your bill amount. System will fetch amount from biller and displays it on the screen once agent enter the consumer number.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the different payment modes available in OASYS PAY platform?
                                    </a>
                                    <p class="accordion-content">
                                        Currently we are giving only <span class="font-wt-600">Cash </span>payment mode facility through which consumer can make a bill payment.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Will customer get a payment receipt after the bill payment has been made?
                                    </a>
                                    <p class="accordion-content">
                                        The consumer would get an instant confirmation and receipt once the transaction is successfully completed. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How does customer know that their bill is paid?
                                    </a>
                                    <p class="accordion-content">
                                        A customer will get successful transaction status and BBPS bill receipt is sent to the customer through electronic/ email/ SMS.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What customer details are required for electricity bill payment?
                                    </a>
                                    <div class="accordion-content">
                                        <p>
                                            You will need the following customer details for electricity bill payment: </p>
                                        <ul class="article-features-list-faq">
                                            <li>Customer Mobile Number</li>
                                            <li>Biller Name / Location</li>
                                            <li>Consumer Number (This field will change according to Biller Name).</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the maximum amount of electricity bill that can be paid?
                                    </a>
                                    <p class="accordion-content">
                                        There is no maximum limit on the electricity bill that can be paid. But, if the bill amount is above Rs.50,000, PAN card is required.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How many days before the due date does electricity bill needs to be paid?
                                    </a>
                                    <p class="accordion-content">
                                        You need to pay your electricity bill at least 2-3 working days prior to the due date.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Electricity bill payment is successful but bill amount isn’t updated. What can be done?
                                    </a>
                                    <p class="accordion-content">
                                        If the payment is successful, please wait for 24 working hours for the payment to reflect in provider’s account. If it isn’t updated in 24 hours, Agent can raise ticket from Mobile/POS go to <span class="font-wt-600">Help/support menu-> Create Tickets</span> Or Agent can call / mail the Help Desk at <a href="customercare@oasyspay.in">1800 572 2770 / customercare@oasyspay.in
                                        </a> to report the query.  
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Electricity bill payment has failed but amount has been debited from my trade wallet. What should I do?
                                    </a>
                                    <p class="accordion-content">
                                        If a bill payment has failed but the amount has been debited from your trade wallet, this amount will be reversed back in 24 working hours.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can customer raise a complaint?
                                    </a>
                                    <div class="accordion-content">
                                        <p>
                                            A customer can raise a complaint directly from any bank application/BBPS Website/OASYS PAY Outlet. There could
                                            be two type of complaints: </p>
                                        <p> <span class="font-wt-600">a. Transaction based complaint: </span>This type of complaint is raised by a customer
                                            either for a successful / pending / failed transaction. The customer can initiate a complaint either at any
                                            OASYS PAY outlet/ bank-channel or the BBPS website. Mobile No. or Transaction ID will be used as a unique
                                            reference number for raising complaints. </p>
                                        <p><span class="font-wt-600">b. Service based complaint: </span>This type of complaint is raised by a customer for
                                            non-transactional issues Transaction based complaints will undergo validation at NPCI and biller levels.</p>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are transaction charges for a bill payment?
                                    </a>
                                    <p class="accordion-content">
                                        Although there are nil charges for any bill payment, but sometimes physical OASYS PAY outlet agent can charge only prescribed convenience fee approved by OASYS PAY for a transaction to customer.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is CCF in BBPS?
                                    </a>
                                    <div class="accordion-content">
                                    <p>
                                        As per NPCI guidelines, every Collection Operation Unit (COU) can charge Customer Convenience Fees (CCF) for each
                                        transaction as per standards specified by NPCI.<br/>
                                        CCF is applicable only for some services upto ₹25.</p>
                                    <div class="comparisons-table table-responsive">
                                        <table class="table" style="width: 60% !important;">
                                            <thead>
                                                <tr>
                                                    <th>Slab </th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0-300</td>
                                                    <td>₹5</td>
                                                </tr>
                                                <tr>
                                                    <td>301-600</td>
                                                    <td>₹10</td>
                                                </tr>
                                                <tr>
                                                    <td>601-900</td>
                                                    <td>₹5</td>
                                                </tr>
                                                <tr>
                                                    <td>1201-5000</td>
                                                    <td>₹20</td>
                                                </tr>
                                                <tr>
                                                    <td>5001-10000</td>
                                                    <td>₹25</td>
                                                </tr>
                                                <tr>
                                                    <td>10001-25000</td>
                                                    <td>₹25</td>
                                                </tr>
                                                <tr>
                                                    <td>250001-49999</td>
                                                    <td>₹25</td>
                                                </tr>
                                                <tr>
                                                    <td>50000-100000</td>
                                                    <td>₹25</td>
                                                </tr>
                                                <tr>
                                                    <td>100001-500000</td>
                                                    <td>₹25</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="dmt" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the Domestic Money Transfer (DMT)?
                                    </a>
                                    <p class="accordion-content">
                                        Domestic Money Transfer (DMT) is a unique product that can be used to send money instantly to any Bank’s account holder within India. Through this product any Indian citizen can walk in with cash-in-hand to any nearest OSS Outlet, Agent will fill few details and have the money transferred to loved ones, anywhere in the country.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the Process involved in Money Transfer Service?
                                    </a>
                                    <div class="accordion-content">
                                        <p> There are typically four processes involved in DMT Process:</p>
                                        <ul class="article-features-list-faq">
                                            <li>Sender's Addition</li>
                                            <li>Beneficiary Addition</li>
                                            <li>Beneficiary's Bank Account Verification</li>
                                            <li>Sending Money to Beneficiary Bank Account</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How much can an agent charge to the sender for sending money, and how much commission can the agent earn from the transaction?
                                    </a>
                                    <div class="accordion-content">
                                    <p>Please refer to the below table;</p>
                                    <div class="comparisons-table table-responsive">
                                        <table class="table" style="width: 100% !important; margin-left:0px !important;">
                                            <thead>
                                                <tr>
                                                    <th colspan="3" style="text-align: center;">Money Transfer</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 22% !important;">Slab (in Rs.)</th>
                                                    <th>Customer Charges will be deducted from Agent's wallet</th>
                                                    <th>Agent Commission (in Rs.)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1 *</td>
                                                    <td>Rs. 5</td>
                                                    <td>NA</td>
                                                </tr>
                                                <tr>
                                                    <td>2-1000 #</td>
                                                    <td>Rs. 10</td>
                                                    <td>25% of Customer Charges</td>
                                                </tr>
                                                <tr>
                                                    <td>1001 - 5000</td>
                                                    <td>1% of Transaction Amount</td>
                                                    <td>25% of Customer Charges</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3">
                                                        <p><span class="font-wt-600">Note: </span>Agent Commission is Exclusive of GST.<br/>
                                                            * Rs. 1 will be used only for Beneficiary Account verification<br/>
                                                            # Minimum Txn Amount is Rs.200<br/>
                                                            # Monthly Limit 25,000, But in one time maximum Amount Sender can send that is 5,000. </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is the Sender needs to submit any physical identity proof for doing any Domestic Money Transfers (DMT)?
                                    </a>
                                    <p class="accordion-content">
                                        No, Sender does not have to submit any physical identity proof for processing the request but Sender has to give his / her minimum details like Name, Mobile Number, DOB, and Address before sending Money. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is there any limit for Sender to add several beneficiaries?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, at any moment a sender can add a maximum of 15 beneficiaries.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How long does it take for the money to get credited into the beneficiary account?
                                    </a>
                                    <p class="accordion-content">
                                        For every successful transaction, instantly money will be credited to the Beneficiary's Bank Account in Real-time.	
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What happens in case Sender provided the wrong beneficiary details while sending money?
                                    </a>
                                    <p class="accordion-content">
                                        The funds will be remitted to the account details provided by the Sender and OSS will not be liable for any wrong credit to the beneficiary as the bank relies completely on the details shared by the Sender.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Will Sender receive any intimation from OSS for completion of the transaction?
                                    </a>
                                    <p class="accordion-content">
                                        On successful completion of the transaction, Success Message will be sent on Sender’s registered mobile number from the OSS and agent will receive a print receipt in their device (POS/Mobile).
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Will Sender and Agent receive any intimation from the OSS if the transaction was failed?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, Sender will receive an SMS on his registered Mobile Number, and Agent will receive a Notification Message Regarding the same.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        From when will the risks be covered after buying the Accident Cover insurance policy?
                                    </a>
                                    <p class="accordion-content">
                                        The risks will be covered from the policy purchase date, as mentioned on the policy. Please check the policy document sent to your email address for more details. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What Will happen if the transaction was failed after a timeout?
                                    </a>
                                    <p class="accordion-content">
                                        The sender will receive a message with the OTP stating that your transaction has failed and you can take your amount by showing this OTP to the same agent. 
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="dth" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Who do I contact if I have more questions about DTH Recharge?
                                    </a>
                                    <p class="accordion-content">
                                        You can call or email to our customer service executive at "1800 572 2770" or <a href="customercare@oasyspay.in">customercare@oasyspay.in</a> respectively.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is there any commission credited when using this service?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, Agent Wallet will be credited with some percentage from the transaction value as a commission which differs from one operator to another operator. Please refer “Service Charges & Commission” menu for further details.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Where can I see the commission percentage for this service?
                                    </a>
                                    <p class="accordion-content">
                                        Please refer “Service Charges & Commission” menu for further details.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What if a transaction is showing Success but benefit is not passed to customers?
                                    </a>
                                    <p class="accordion-content">
                                        For these types of cases, please raise dispute and we will get back you within 3 working days. Also you can raise ticket from Help/support menu-> Create Tickets or Agent can call / mail the Help Desk at 1800 572 2770 or <a href="customercare@oasyspay.in">customercare@oasyspay.in</a> to report the query. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Why am I unable to place a DTH recharge order? 
                                    </a>
                                    <p class="accordion-content">
                                        Please ensure that you add the correct details, while requesting a recharge. Sometimes the delay might also be possible due to technical issues. We recommend that you retry performing the recharge after sometime in such cases.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Why am I unable to place a DTH recharge order? 
                                    </a>
                                    <p class="accordion-content">
                                        Please ensure that you add the correct details, while requesting a recharge. Sometimes the delay might also be possible due to technical issues. We recommend that you retry performing the recharge after sometime in such cases.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        My payment is successful but my order status still shows as 'pending'.
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>Recharges take anywhere between 10 seconds to 20 minutes for completion. Please check status after 20 minutes.</li>
                                            <li>You can check the latest status of the recharge by visiting Transaction MIS section.</li>
                                            <li>If your recharge is successful, we will send you a confirmation email and SMS. You can also check the status of recharge from your operator by quoting the Operator Reference Number.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        My recharge failed but payment was successful, why so? When will I receive a refund?
                                    </a>
                                    <div class="accordion-content">
                                        <p>If the details you entered for your recharge plan are valid, the recharge will be successful. In cases of incorrect details or technical failure, the payment goes through but recharges fail at the service provider’s end.</p>
                                        <ul class="article-features-list-faq">
                                            <li>Recharges done using OSS Pay balance will be refunded instantly for such failed transactions.</li>
                                            <li>For other payment methods, the refund will be processed in 2-4 business days, depending upon bank's policy. Bank usually sends you an SMS once the refund is successfully credited.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I cancel my DTH order?
                                    </a>
                                    <p class="accordion-content">
                                        No, a DTH recharge once placed cannot be cancelled.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I recharge my DTH?
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>You can recharge your DTH on the OSS application, Just go to the  DTH Recharge option and follow the on-screen instructions.</li>
                                            <li>Select the operator and enter the subscriber ID/customer ID/ Smart Card No/Registered Mobile Number.</li>
                                            <li>Enter the amount and click Pay. After payment is successful, you will receive a confirmation email and SMS.</li>
                                        </ul>
                                    </div>
                                </li>
                                <!-- <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I find my Subscriber ID/ Customer ID / Smart Card Number?
                                    </a>
                                    <div class="accordion-content" style="margin-top: 10px;">
                                    <div class="comparisons-table table-responsive">
                                        <table class="table" style="width: 100% !important; margin-left:0px !important;">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p><span class="font-wt-600">Airtel Digital TV </span></p>
                                                        <ul class="article-features-list-faq" style="margin-left: 0px !important;">
                                                            <li>Customer ID is a 10-digit number that starts with the digit '3'</li>
                                                            <li>To know your customer ID, press 'Menu' on your DTH remote and choose My account</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <p><span class="font-wt-600" style="text-align: initial;">Dish TV </span></p>
                                                        <ul class="article-features-list-faq" style="margin-left: 0px !important; text-align: initial;">
                                                            <li>Recharge can be done using the registered mobile number or the viewing card number</li>
                                                            <li>Viewing card number is 11-digits long and starts with the digit '0'</li>
                                                            <li>You can find this at the back of the card inserted in the set-top box</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2-1000 #</td>
                                                    <td>Rs. 10</td>
                                                </tr>
                                                <tr>
                                                    <td>1001 - 5000</td>
                                                    <td>1% of Transaction Amount</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="mobile" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is Mobile Prepaid Recharge?
                                    </a>
                                    <p class="accordion-content">
                                        Mobile Prepaid Recharge is one of the services where an end customer can recharge / top up his/her Prepaid Mobile Number.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are all the Payment collection modes available for Mobile Prepaid Recharge?
                                    </a>
                                    <p class="accordion-content">
                                        You can recharge customer’s mobile prepaid number using taking Cash feature currently. In future, UPI / Aadhaar Pay as Payment collection mode will be come.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is there any commission credited when using this service?
                                    </a>
                                    <p class="accordion-content">
                                        Yes. Agent Wallet will be credited with some percentage from the transaction value as a commission which differs from one operator to another operator. Please refer “Service Charges & Commission” menu for further details.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Where can I see the commission percentage for this service?
                                    </a>
                                    <p class="accordion-content">
                                        Please refer “Service Charges & Commission” menu for further details.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Any SMS will be notified to the customer mobile number after a successful / failed transaction?
                                    </a>
                                    <p class="accordion-content">
                                        Yes. Customer Mobile number will be notified from Customer’s Mobile Service Provider end against amount recharged for any successful transaction as per Mobile Service Provider policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What if a transaction is failed but the amount for this transaction debited from the wallet account?
                                    </a>
                                    <p class="accordion-content">
                                        For failure transactions, if amount debited wrongly then the amount will be auto-credited into wallet within 24 working hours. Also you can raise a ticket / call to our customer support executive for more details to get it clarified.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What if a transaction is showing Success but benefit is not passed to customers?
                                    </a>
                                    <p class="accordion-content">
                                        For these type of cases, please raise dispute and we will get back you within 3 working days. If not then agent can raise ticket from Help/support menu-> Create Tickets. Or Agent can call/mail the Help Desk at 1800 572 2770 or <a href="customercare@oasyspay.in">customercare@oasyspay.in</a> to report the Questionry.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="accidental" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is accident insurance?
                                    </a>
                                    <p class="accordion-content">
                                        Accident insurance coverage provides you with a lump-sum payment when you suffer a permanent disability or death. There is no limit on the number of different accidents that will be covered. Payments are made directly to you to use as you see fit. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Who is eligible to enrol for this accident coverage? 
                                    </a>
                                    <p class="accordion-content">
                                        The Insurer is eligible to enrol themselves and are eligible for their family members as well but they should be enrolled individually. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        I have a good medical plan at work. Why do I need Accident insurance?
                                    </a>
                                    <p class="accordion-content">
                                        While Health Insurance provides coverage for medical expenses, Accidental Insurance offers additional benefits such as lump-sum payouts for disability or death resulting from accidents. Having both types of coverage can provide comprehensive protection.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I enrol for this insurance without having to take a medical exam?
                                    </a>
                                    <p class="accordion-content">
                                        Yes! Your accident coverage is guaranteed issue, which means your acceptance is guaranteed, regardless of your health. There are no medical exams to take and no health Questions to answer
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Who can buy this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Anyone in the age group of 18- 65 years can buy this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the benefits of Accident Cover insurance policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Here’s why you should buy the Accident Cover insurance policy:</p>
                                        <ul class="article-features-list-faq">
                                            <li>There is no health check-up required to avail this policy.</li>
                                            <li>Your policy is available 24X7 online. Apart from the above benefits, the Accident Cover insurance policy provides a lump sum payout to your nominee or you, as the case may be, as:</li>
                                            <ol class="article-features-list-faq"> 
                                                <li>
                                                    Cover against accidental death and permanent total disability due to an accident.
                                                </li>
                                                <li>Cover against different types of accidents, including road accidents, rail accidents, accidents due to natural calamities, and accidents arising out of any type of terrorism or terrorist acts.</li>
                                            </ol>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is a Free- Look option available in this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, free look option is available in this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the premium amount that I have to pay for this policy?
                                    </a>
                                    <p class="accordion-content">
                                        The premium amount that you have to pay for this policy depends on the sum insured you choose. Please check for the exact premium amount and coverage on our app while purchasing the policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can customer get tax benefit for the premium paid?
                                    </a>
                                    <p class="accordion-content">
                                        No, Tax benefit is not applicable for the policy. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        From when will the risks be covered after buying the Accident Cover insurance policy?
                                    </a>
                                    <p class="accordion-content">
                                        The risks will be covered from the policy purchase date, as mentioned on the policy. Please check the policy document sent to your email address for more details. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the waiting period under this policy?
                                    </a>
                                    <p class="accordion-content">
                                        There is no initial waiting period for this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        When will be the Policy documents handed over to the customers?
                                    </a>
                                    <p class="accordion-content">
                                        Policy documents will be available to download in the real-time. Go to Service wise Report-> Select Service Category as Accidental Insurance-> Policy document download in last column. Customer will also receive the documents in their Email.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What happens when the amount is deducted from my wallet and getting the failure Pop-up message?
                                    </a>
                                    <p class="accordion-content">
                                        If failure message is pop-up then the Trade wallet amount should be credited in real time. If not then agent can raise ticket from Help/support menu-> Create Tickets. Or Agent can call/mail the Help Desk at 1800 572 2770/customercare@oasyspay.in to report the query.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I cancel my policy?
                                    </a>
                                    <p class="accordion-content">
                                        To do so, customer has to raise request with <span class="font-wt-600">“Acko General Insurance Ltd”. </span>  Customer can call on 1800 266 2256 or write at <a href="finhaatcare@acko.com">finhaatcare@acko.com</a>
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do I charge my customer for this service?
                                    </a>
                                    <p class="accordion-content">
                                        No. You should not charge the customer for this service. You will receive a commission for your transaction.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I make corrections of my personal details in issued policy?
                                    </a>
                                    <p class="accordion-content">
                                        Customer has to raise request with <span class="font-wt-600">“Acko General Insurance Ltd”. </span>  Customer can call on 1800 266 2256 or write at <a href="finhaatcare@acko.com">finhaatcare@acko.com</a>
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="hospicash" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is Hospital Daily Cash (HospiCash) Insurance?
                                    </a>
                                    <div class="accordion-content">
                                        <p> Hospital Daily Cash Insurance gives you a daily benefit for every 24 hours of hospitalisation. The coverage commences after the first 24 hours of admission, if the reason for hospitalisation is not falling under exclusion in the Policy. The cash benefit, depending on the plan, ranges from ₹ 500/day to ₹ 2000/day for normal Hospitalization and ₹ 1000/day to ₹ 4000/day for ICU Hospitalization up to 30 days. <br/>
                                        Other benefits of the policy are: </p>
                                        <ul class="article-features-list-faq">
                                            <li>Accident Benefit if the person is hospitalised due to an accident for maximum 5 days.</li>
                                            <li>Accident Benefit if the person is hospitalised in an ICU for maximum 7 days.</li>
                                            <li>Triple Accident Benefit or INR 5000 whichever is less of cash benefit upon completion of 10 consecutive days of hospitalization in a single admission for convalescence.</li>
                                            <li>Common sum insured for permanent total disability and permanent partial disability.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the features of Hospital Daily Cash Health Insurance Policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p> The policy offers fixed benefits irrespective of the actual expenses. Some key features of the hospital daily cash health insurance policy are; </p>
                                        <ul class="article-features-list-faq">
                                           <li>The policy is available for anyone with a minimum age of 18 years and a maximum of 60 years.</li>
                                           <li>Children from 1 months' age are covered if any one parent is covered.</li>
                                           <li>For individuals below 45 years of age, no pre-policy medical check-up is required.</li>
                                           <li>The plan has 4 variants offering daily cash benefits from ₹500 up to ₹ 2000.</li>
                                           <li>The coverage options available offering benefits for 30 days of hospitalization per year.</li>
                                           <li>There is a waiting period of 24 hours of hospitalisation, after which daily cash benefit is available.</li>
                                           <li>For ICU and accidental hospitalisation, expenses are covered up to twice the daily benefit amount.</li>
                                           <li>Premium paid for the policy is tax-free under section 80D of the Income Tax Act.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the coverages offered by Hospital Daily Cash Insurance Policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p> Following is the main inclusions in the policy. </p>
                                        <ul class="article-features-list-faq">
                                            <li>
                                                Hospitalisation for more than 24 hours is covered, and daily cash benefit for each day is provided.
                                            </li>
                                            <li>For ICU and accidental hospitalisation, twice the amount of daily cash benefit is provided for a maximum of 7 days and 5 days, respectively. In a policy period, accidental hospitalisation of a maximum of 10 days is covered, and ICU hospitalisation of a maximum of 15 days is covered.</li>
                                            <li>A convalescence benefit is also covered for 10 continuous days of hospitalisation, whichever less than thrice the amount of daily cash benefit or ₹ 5000 is payable.</li>
                                            <li>The maximum hospitalisation days covered in the policy are 30 days as per your policy terms.</li>
                                            <li>The expenses covered under the policy include consumables, personal items needed during hospitalisation, travel costs, the cost of a personal attendant, etc.</li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What plans are available under the Hospital Daily Cash Insurance Policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p>  </p>
                                        <ul class="article-features-list-faq">
                                            <li> Bronze plan- ₹ 500</li>
                                            <li> Silver Plan- ₹ 1000</li>
                                            <li> Gold Plan- ₹ 1500</li>
                                            <li> Platinum Plan- ₹ 2000</li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How is Hospital Daily Cash Insurance different from Health Insurance?
                                    </a>
                                    <p class="accordion-content">
                                        Health Insurance policy reimburses inpatient hospital bills. Hospital Daily Cash offers you fixed daily benefits, per 24 hours spent in hospital. This is aimed in covering the incidental expenses during hospitalisation which are normally not covered under health insurance. Hospital Daily Cash Insurance is NOT a substitute of Health Insurance. Rather it acts as a supplement.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the benefits of Hospital Daily Cash Insurance Policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p> A health insurance hospital cash benefit plan has several benefits as given below; </p>
                                        <ul class="article-features-list-faq">
                                            <li>
                                                <span class="font-wt-600">Covers miscellaneous expenses</span><br/>
                                                <p>During hospitalization, Insurers do not cover certain expenses which are coined as Inadmissible Expenses, for example- expenses incurred on Diagnostic tests, Surgical Accessories, or costs of patient’s attendant. <br/>
                                                    Irrespective of these inclusions& exclusions, the Insurance Company pays Hospital Daily Cash to the policy holder. It can be used to cover hospitalization costs as well as other incidental expenses such as travelling costs, household bills, food expenses, and the cost of attendant’s transportation. You can utilize the money to cover the costs which are otherwise not covered under a standard Health Plan.</p>
                                            </li>
                                            <li>
                                                <span class="font-wt-600">Protects No Claim Bonus</span><br/>
                                                <p>The Hospital Daily Cash amount can be utilised to pay small hospital bills for minor ailments or procedure.<br/>
                                                    For example, Mr. Rajesh was admitted in the hospital for 2-3 days for a minor illness, for which, the hospital bill was no so huge, He paid the bill with his Hospital Daily Cash amount rather than filing a claim under Health Insurance policy. <br/>
                                                    Hospital Daily Cash assists you in paying off your medical expenses prevents filing a claim under Health Insurance Policy and allow you to preserve No-claim Bonus.
                                                    </p>
                                            </li>
                                            <li>
                                                <span class="font-wt-600">No bills required</span><br/>
                                                <p>Hospital cash is the fixed amount you get each day of hospitalisation, and you need not provide any hospital bill or any other document to claim this amount. No matter what your additional expenses are, the amount will be paid as per your policy terms.</p>
                                            </li>
                                            <li>
                                                <span class="font-wt-600">Compensates income loss (Convalescence Benefit)</span><br/>
                                                <p>If you are the only earning member in your family, the daily cash benefit helps to meet basic expenses for the period you are hospitalised and thus supports you during income loss.</p>
                                            </li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the deductible under the policy?
                                    </a>
                                    <p class="accordion-content">
                                        An excess equivalent to the first 24 hours Hospitalization benefit will be levied on each and every Hospitalisation during the Policy Period.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Who can buy this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Anyone in the age group of 18- 60 years can buy this policy. Children can be covered after 90 days if either of the parents are concurrently insured with us
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the waiting period under this policy?
                                    </a>
                                    <div class="accordion-content">
                                        <ul class="article-features-list-faq">
                                            <li>
                                                There is a initial waiting period of 30 days before you can claim under this policy.
                                            </li>
                                            <li> For Pre-existence Diseases the waiting period is 2 years before you claim under this policy.</li>
                                            <li>Maternity waiting period is 9 months before you can claim under this policy</li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are Sum Insured restrictions in the policy?
                                    </a>
                                    <p class="accordion-content">
                                        Minimum Benefit Amount is ₹ 500/day and Maximum Benefit Amount is ₹ 2,000/day. However, the Maximum Benefit would be restricted to ₹ 500/per day with maximum coverage of 30 days for Insured aged 60 and above and taking a policy with us for the first time.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What plan options are available under this policy?
                                    </a>
                                    <p class="accordion-content">
                                        You can buy a maximum 30 days of hospitalisation plan and choose the daily cash benefit from ₹ 500/day to 2000/day under this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do I need to undergo Pre-Acceptance Health Check Up compulsorily?
                                    </a>
                                    <div class="accordion-content">
                                        <p> Pre-acceptance Health check is not mandatory for people who are less than 45 years old and without any history of illness. Insured’s with following condition, acceptance of proposal is subject to a satisfactory medical examination as per SBI General’s requirements: </p>
                                        <ul class="article-features-list-faq">
                                            <li>Insured with adverse medical history as declared in Proposal Form or,</li>
                                            <li>Insured aged above 45 years &amp; irrespective of SI.</li>
                                        </ul>
                                        <p>The cost of Pre-acceptance medical tests has to be borne by the proposer. However, if the proposal is accepted by Us, we will reimburse 50% of the cost incurred towards the medical tests so undertaken at our advice.</p>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is a Free- Look option available in this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Yes, free look option is available in this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What do you mean by Pre-Existing Disease? Are pre-existing diseases covered in this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Any condition, aliment or injury or relation condition(s), for which the insured had signs or symptoms and/or diagnosed and/or received medical advice/treatment prior to the inception of the policy. Pre-existing diseases are permanently excluded from coverage in this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Can I port any other policy into this policy?
                                    </a>
                                    <p class="accordion-content">
                                        Portability is applicable only within similar products. In this case you can port your existing Hospital Daily Cash policy into this policy.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the tax benefits that can be availed on this plan?
                                    </a>
                                    <div class="accordion-content">
                                        <p> Deduction u/s 80 D of income tax act is available from taxable income for premium paid towards health insurance. </p>
                                        <ul class="article-features-list-faq">
                                            <li>
                                                For self, spouse and dependent children - Maximum ₹25000/-
                                            </li>
                                            <li>For Parent(s)- Maximum ₹25,000/-</li>
                                            <li> In case Parent(s) are resident senior citizen- Maximum ₹30,000/-</li>
                                        </ul>
                                        <p>However, please note that tax benefits are subject to change in tax laws.</p>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Will Hospital Daily Cash Insurance cover maternity?
                                    </a>
                                    <p class="accordion-content">
                                        No, Hospital Daily Cash Insurance does not cover hospitalisation due to maternity
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        While my Health Policy covers day care treatment, will Hospital Daily Cash covers the same?
                                    </a>
                                    <p class="accordion-content">
                                        No. Hospital Daily Cash Insurance does not cover expenses due to day care treatment
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        If I already have BP and wish to avail Hospital Daily Cash Insurance, how can I go about it?
                                    </a>
                                    <p class="accordion-content">
                                        You may go ahead and purchase Hospital Daily Cash Insurance. However, your pre-existing diseases like BP (Hypertension) and any heart, kidney having waiting period of 24 months.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the exclusion under Hospital Daily Cash Insurance Policy?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Any claim caused by, based on, arising out of or attributable to any of the following will not be covered under Hospital Daily Cash Insurance policy:  </p>
                                        <ul class="article-features-list-faq">
                                            <li>Eyesight die to refractive error </li>
                                            <li>Dental Treatement </li>
                                            <li>Maternity Treatment </li>
                                            <li>Change of Gender </li>
                                            <li>Self-Injury or Suicide attempt </li>
                                            <li>Hazardous or Adventurous sports </li>
                                            <li>Gestational Surrogacy </li>
                                            <li>Sleep disorder, Parkinson and Alzheimer </li>
                                            <li> Nuclear, chemical. Or biological attacks or weapons</li>
                                            <li>Treatment of Alcoholism & Drugs </li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the Claim Process?
                                    </a>
                                    <div class="accordion-content">
                                        <p>Only Reimbursement option is available for filing a claim under Daily Hospital Cash. Reimbursement means payment at a later stage. </p>
                                        <p>Below are the steps that you have to follow to file a claim for your reimbursement:</p>
                                        <ul class="article-features-list-faq">
                                            <li>
                                                <span class="font-wt-600">Claim Intimation </span><br/>
                                                <p>Notify the company in writing within 48 hours after hospitalisation in the case of an emergency and 48 hours before hospitalisation in the case of planned hospitalisation.</p>
                                            </li>
                                            <li>
                                                <span class="font-wt-600">Document Submission </span><br/>
                                                <p>Provide the necessary documents within 15 to 30 days of discharge from the hospital, one who is filing the claim must provide the necessary documents to the company.</p>
                                            </li>
                                            <li>
                                                <span class="font-wt-600">Other Documents </span><br/>
                                                <p>One who is claiming on your behalf must notify the company in writing and submit a copy of the post-portem report (if any) within 30 days of death.</p>
                                            </li>
                                        </ul>
                                        </div>
                                </li>
                                
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What are the Required Documents?
                                    </a>
                                    <div class="accordion-content">
                                        <p> Following documents are required for Claim Settlement Process:  </p>
                                        <ul class="article-features-list-faq">
                                            <li>
                                                Duly- filled claim form signed by the Insured
                                            </li>
                                            <li>Medical certificate issued by the doctor and authorised by the hospital along with the attached diagnosis report</li>
                                            <li>Copy of the Discharge Summary of Discharge Certificate</li>
                                            <li>Copy of Hospital Bills like Prescription and cash invoice from pharmacy/hospital</li>
                                            <li>FIR (in case of the accident)</li>
                                            <li>A copy of ID proof of the claimant (Aadhaar Car, PAN Card, or any other government photo ID).</li>
                                        </ul>
                                        </div>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        When will be the Policy documents handed over to the customers?
                                    </a>
                                    <p class="accordion-content">
                                        Policy documents will be available to download in the real-time. Go to Download Certificates->Policy Document.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I make corrections of my personal details in issued policy?
                                    </a>
                                    <p class="accordion-content">
                                        Customer has to raise request with “Acko General Insurance Ltd”.  Customer can call on 1800 266 2256 or write at finhaatcare@acko.com. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What happens when the amount is deducted from my wallet and getting the failure Pop-up message?
                                    </a>
                                    <p class="accordion-content">
                                        If failure message is pop-up then the Trade wallet amount should be credited in real time. If not then agent can raise ticket from Help/support menu-> Create Tickets. Or Agent can call/mail the Help Desk at 1800 572 2770/customercare@oasyspay.in to report the query.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I cancel my policy?
                                    </a>
                                    <p class="accordion-content">
                                        To do so, customer has to raise request with “Acko General Insurance Ltd”.  Customer can call on 1800 266 2256 or write at finhaatcare@acko.com
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do I charge my customer for this service?
                                    </a>
                                    <p class="accordion-content">
                                        No. You should not charge the customer for this service. You will receive a commission for your transaction.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="matm" role="tabpanel">
                        <div class="faq-accordion-general">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is Micro ATM? What are its features?
                                    </a>
                                    <p class="accordion-content">
                                        With Micro ATM you can provide cash withdrawal transactions using any bank’s debit card and PIN. Only cash withdrawals and balance enquiry can be done through Micro ATM.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What all types of cards are accepted? 
                                    </a>
                                    <p class="accordion-content">
                                        You can use any bank’s debit card to make a cash withdrawal transaction. All cards issued by Visa, MasterCard & RuPay are accepted. Currently, we support only Debit Card cash withdrawal, and don’t allow credit card or prepaid card.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Where can I buy Micro ATM from?
                                    </a>
                                    <p class="accordion-content">
                                        You can also order your device from your distributor and super distributor. Cost of the device will be debited from the wallet and device will be delivered to your registered address. 
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How can I use the Micro ATM device?
                                    </a>
                                    <p class="accordion-content">
                                        To use the Micro ATM device, simply pair it to your mobile phone, insert debit card, and enter the amount. Let customer enter their PIN. Upon a successful message, hand over cash to the customer.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Do I charge my customer for MATM service?
                                    </a>
                                    <p class="accordion-content">
                                        No. You should not charge the customer for MATM service. You will receive a commission for your transaction.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the warranty on the device?
                                    </a>
                                    <p class="accordion-content">
                                        There is 6 months warranty on the device and 6 month on battery.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Is there any transaction SMS sent to the customer?
                                    </a>
                                    <p class="accordion-content">
                                        Transaction confirmation SMS is sent to the mobile number captured by the retailer while initiating the transaction.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How does a retailer identify the transaction done by Micro ATM?
                                    </a>
                                    <p class="accordion-content">
                                        The retailer will be able to view the transaction in the report section under the Micro ATM tab.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the withdrawal limit per transaction?
                                    </a>
                                    <p class="accordion-content">
                                        Maximum ₹10000 can be withdrawn per transaction. Total cash withdrawal amount is decided by the issuing bank.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        How many transactions can be done in a day?
                                    </a>
                                    <p class="accordion-content">
                                        There is no limit on number of transaction that can be performed by retailer. However customer transactions are limited by issuing bank.
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What should I do, if I face any technical issue with the device?
                                    </a>
                                    <p class="accordion-content">
                                        Agent can raise ticket complaints from Mobile/POS, Go to <span class="font-wt-600">Help/support menu-> Create Tickets. </span>
                                        Or Agent can call / mail the Help Desk at 1800 572 2770 / customercare@oasyspay.in to report the query.  
                                    </p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        What is the retailer’s commission on the transactions?
                                    </a>
                                    <p class="accordion-content">
                                        Micro ATM commission is the same as the existing AePS commission.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div> 
                    
                </div>

                
            </div>
        </div>
    </div>
</div>
<!-- End FAQ Area -->

<!-- <app-account-button></app-account-button> -->
