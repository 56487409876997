<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div
            class="container"
            [ngClass]="{'container-fluid': router.url === '/demo-7'}"
        >
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/" style="width: 8%;">
                    <!-- <img src="assets/img/logo.png" alt="logo"> -->
                    <img src="assets/img/logo-black-logo.png" alt="logo">
                    <img src="assets/img/logo-black-logo.png" alt="logo">
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Products & Services <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu" style="width: 100%;">
                                <li class="nav-item">
                                    <a routerLink="/banking-services" routerLinkActive="active" class="nav-link">Banking Services</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/utility-bill-payment" routerLinkActive="active" class="nav-link">Utility Bill Payment</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/g2c-services" routerLinkActive="active" class="nav-link">G2C Services</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/insurance" routerLinkActive="active" class="nav-link">Insurance</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/credit-services" routerLinkActive="active" class="nav-link">Credit Services</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a routerLink="/tour-travels" routerLinkActive="active" class="nav-link">Tour & Travels</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/b2c-services" routerLinkActive="active" class="nav-link">B2C Services</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/more" routerLinkActive="active" class="nav-link">More Services</a>
                                </li> -->
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/grievance-complaints" routerLinkActive="active" class="nav-link">Grievance & Complaints</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Company <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu" style="width: 90%;">
                                <!-- <li class="nav-item">
                                    <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                                </li> -->
                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ's</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/training" routerLinkActive="active" class="nav-link">Training</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"><i class="flaticon-user"></i> Log In <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu" style="width: 90%;">
                                <li class="nav-item">
                                    <a href="https://agent.oasyspay.in" target="_blank" routerLinkActive="active" class="nav-link">Agent</a>
                                </li>
                                <li class="nav-item">
                                    <a href="https://distributor.oasyspay.in" target="_blank" routerLinkActive="active" class="nav-link">Distributor</a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ's</a>
                        </li> -->
                        
                        <!-- <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Gallery <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/training" routerLinkActive="active" class="nav-link">Training</a>
                                </li>
                            </ul>
                        </li> -->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->