<!-- Start Fun Facts Area -->
<div class="funfacts-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>We always try to understand customers expectation</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="180">00</span>K</h3>
                    <p>Retailers</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="20">00</span>K</h3>
                    <p>Distributor</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="500">00</span>+</h3>
                    <p>Transaction</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="70">00</span>+</h3>
                    <p>API Partner</p>
                </div>
            </div>
        </div>
        <!-- <div class="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us</p>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
        </div> -->
        <!-- <div class="map-bg">
            <img src="assets/img/map.png" alt="map">
        </div> -->
    </div>
</div>
<!-- End Fun Facts Area -->