<!-- Start Page Title Area -->
<div class="page-title-area item-bg-utility jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start BBPS Services Area -->
<div class="services-area ptb-80" #focusBbps>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">BBPS?</h1>
                    <div class="bar"></div>
                    <p>BBPS, or the Bharat Bill Payment System, is a centralized payment platform in India that facilitates bill payments across various utility and service providers. It was introduced by the National Payments Corporation of India (NPCI) to streamline and digitize the bill payment process, making it more convenient and efficient for consumers.</p>
                    <p>OASYS PAY agent allows users to pay bills for a wide range of services, including electricity, water, gas, telecommunications, and more, all through a single platform. This eliminates the need for multiple payment channels and provides a unified interface for bill payment transactions.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/products-services/BBPS.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 75% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BBPS Services Area -->

<!-- Start DTH Recharge Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/DTH.png" alt="image" style="max-width: 85% !important;">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 70% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">DTH Recharge</h1>
                    <div class="bar"></div>
                    <p>Recharge services enable users to top up or replenish DTH prepaid services. DTH (Direct-to-Home) subscriptions conveniently and securely. These services are typically accessible through retail agents affiliated with the OASYS PAY.
                    </p>
                    <p>Recharge services often provide real-time transaction processing, instant confirmation, and secure payment gateways, ensuring a seamless user experience. By offering these services, retailer agents contribute to financial inclusion and make money on all such recharge transactions.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Micro ATM Services Area -->

<!-- Start Money Transfer Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">Mobile Recharge</h1>
                    <div class="bar"></div>
                    <p>Recharge services enable users to top up or replenish Mobile prepaid services. Mobile subscriptions conveniently and securely. These services are typically accessible through retail agents affiliated with the OASYS PAY.</p>
                    <p>Recharge services often provide real-time transaction processing, instant confirmation, and secure payment gateways, ensuring a seamless user experience. By offering these services, retailer agents contribute to financial inclusion and make money on all such recharge transactions.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/mobile-recharge.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Money Transfer Services Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->