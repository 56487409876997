<!-- Start Partner Area -->
<div class="partner-area ptb-30">
    <div class="container">
        <div class="section-title m-t-30">
            <h2>Our Partners</h2>
            <div class="bar"></div>
        </div>

        <div class="partner-inner">
            <div class="row align-items-center">
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/Axis_Bank-Logo.png" alt="partner" style="max-width: 80%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/icici-logo.png" alt="partner" style="max-width: 100%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/yes-bank-logo.png" alt="partner" style="max-width: 90%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/RBL-Logo.png" alt="partner" style="max-width: 80%;">
                    <!-- <a href="#">
                        <img src="assets/img/partner-image/RBL-Logo.png" alt="partner" style="max-width: 70%;">
                        <img src="assets/img/partner-image/RBL-Logo.png" alt="partner" style="max-width: 70%;">
                    </a> -->
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/airtel-logo.png" alt="partner" style="max-width: 100%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/nsdl-logo1.png" alt="partner" style="max-width: 100%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/aeps-logo.png" alt="partner" style="max-width: 90%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/negd-logo.png" alt="partner" style="max-width: 80%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/finhaat-logo.png" alt="partner" style="max-width: 80%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/Umang_logo.png" alt="partner" style="max-width: 90%;">
                </div>
                <div class="col-lg-2 col-sm-4 col-md-2 col-6">
                    <img src="assets/img/partner-image/TVS-Credit-Logo.png" alt="partner" style="max-width: 80%;">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Partner Area -->
<!-- <div class="feedback-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Partners</h2>
            <div class="bar"></div>
        </div>
        <div class="feedback-slides">
            <div class="client-feedback">
                <div hidden>
                    <ngx-slick-carousel
                        class="carousel feedback"
                        #slickModal="slick-carousel"
                        [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>John Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>John Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>Maxwel Warner</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client4.jpg" alt="image">
                                </div>
                                <h3>Ross Taylor</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client5.jpg" alt="image">
                                </div>
                                <h3>James Anderson</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>Steven Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>Steven Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>John Terry</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel
                        class="carousel thumbs"
                        #slickModal="slick-carousel"
                        [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/RBL-Logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/TVS-Credit-Logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/yes-bank-logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/airtel-logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/Axis_Bank-Logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/icici-logo.png" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/partner-image/aeps-logo.png" alt="client"></div>
                        </div>
                       
                    </ngx-slick-carousel>
                </div>
                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->