<!-- Start Page Title Area -->
<div class="page-title-area item-bg-tc jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container text-justify">
        <div class="section-title">
            <h2>TERMS AND CONDITIONS</h2>
            <div class="bar"></div>
        </div>
        <h5 class="sub-headings1 m-t-30 m-b-30">Updated on 5th July 2023</h5>
        <p>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE
            THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL
            SIGNATURES.</p>
        <p>
            <strong>1. </strong>
            You agree that by clicking “Register”, “Sign Up” or similar, registering,
            accessing or using our services,<span class="font-wt-600">you are agreeing to enter into a legally binding
                contract</span> with OASYS Cybernetics Private Limited, having its
            registered office at OAS Towers, No. 3, Stringers Road, Vepery, Chennai -
            600 003) and its group companies may at any time be entitled whether by
            operation of law, contract or otherwise, even if you are using our Services
            on behalf of a company. If you do not agreeto this contract (“Contract”),
            do <span class="font-wt-600">not</span> click “Register” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract,
            at any time you can do so by closing your account and no longer accessing
            or using our Services. OASYS Cybernetics Private Limited, its group
            companies, subsidiaries, sister concerns, may together be hereinafter
            referred to as “OASYS”.
        </p>
        <p>
            <strong>2. </strong>
            The access and use of the website <span class="font-wt-600">“www.oasyspay.in” </span>and/or
            its sub-domain and/or the OASYS PAY App is subject to the following Terms
            and Conditions of use (“Conditions” and/or “Terms”). The services provided
            by OASYS are on mutually agreed basis and it is applicable to the services
            provided in this website, mobile application, apps, communications and
            other services or any similar platform (hereinafter together called as
            “OASYS Platform”).
        </p>
        <p>
            <strong>3. </strong>
            OASYS reserves rights to modify/add/amend/delete any or all the Terms at
            any point of time without notice to you by posting revised Terms and
            Conditions on its applications. Your use of its web-sites &amp;applications
            constitutes your binding acceptance of these Terms, including any
            modifications that OASYS makes. The payment gateway page request for
            merchant to accept terms for each payment transaction signifies this
            consent explicitly.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">4. Eligibility:</h6>
        <p>
            The services provided by OASYS are not available to persons under the age
            of 18 or to anyone previously suspended or removed by OASYS from availing
            the OASYS Services or accessing the OASYS Platform. By accepting the Terms
            and Conditions or by otherwise using the OASYS Services on the OASYS
            Platform, You represent that You are at least 18 years of age and have not
            been previously suspended or removed by OASYS, or disqualified for any
            other reason, from availing the OASYS Services or using the OASYS Platform.
            In addition, You represent and warrant that You have the right, authority
            and capacity to enter into this Agreement and to abide by all the Terms and
            Conditions as part of this Agreement. Finally, You shall not impersonate
            any person or entity, or falsely state or otherwise misrepresent Your
            identity, age or affiliation with any person or entity. Finally, in the
            event of any violation of the Terms and Conditions, OASYS reserves the
            right to suspend or permanently prevent You from availing OASYS Services or
            using the OASYS Platform.
        </p>
        <p>
            <strong>5. </strong>
            OASYS has the sole right, but not the obligation, to take any of the
            following actions in its sole discretion at any time and for any reason
            without giving prior notice:-
        </p>
        <p>
            i. Restrict, suspend, or terminate your access to all or any part of its
            services;
        </p>
        <p>
            ii. Change, suspend, or discontinue all or any part of its services;
        </p>
        <p>
            iii. Refuse, move, or remove any material that you submit to their
            web-sites or applications for any reason;
        </p>
        <p>
            iv. Refuse, move or remove any content that is available on their
            web-sites, applications or on its social media;
        </p>
        <p>
            v. Deactivate or delete your accounts and all related information and files
            in your account; Establish general practices and limits concerning use of
            their web-sites and applications.
        </p>
        <p>
            vi. You agree that OASYS will not be liable to you or any third party for
            taking any of these actions.
        </p>
        <p>
            vii. Some of above actions may be results of any transaction activity which
            may come under “suspicious transactions” and would require immediate action
            without prior intimation as per current regulatory framework.
        </p>
        <p>
            <strong>6. </strong>
            You understand and agree that its services may include communications such
            as service announcements and administrative messages from OASYS or from
            their partners or licensors or associates and that these communications are
            considered part of the Services.
        </p>
        <p>
            <strong>7. </strong>
            You do not have an option to opt out from receiving these messages. You
            also understand that the Services may include advertisements unless you
            have opted out by signing for Do Not Disturb registry for non-transactional
            messages. All messages related to financial transaction and related to use
            of this application will continue to be delivered.
        </p>
        <p>
            <strong>8. </strong>
            You irrevocably and unconditionally agree, confirm, undertake and accept
            these terms and other Terms &amp; Conditions, if any, on registration, for
            access to use of any contents or materials on its web-sites and
            application.
        </p>
        <p>
            <strong>9. </strong>
            Any amendments to these terms, and other Terms &amp; Conditions from time
            to time shall be deemed to be unconditionally accepted by you and it shall
            be an integral part of the original terms, and other Terms &amp;
            Conditions.
        </p>
        <p>
            <strong>10. </strong>
            You confirm to provide OASYS any and every detail OASYS requires to obtain
            from you, including such other personal or proprietary information relating
            to any of your acts or omissions or violations or other persons who may be
            a party with you, on suspicion or otherwise in relation to the use of
            OASYS.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">11. Other web-sites, products and services:</h6>

        <p>
            OASYS web-sites may contain links to other Internet web-sites owned by
            third parties and/or other web-sites owned by OASYS. Your use of each of
            those web-sites is subject to the Terms &amp; Conditions, if any, that each
            of those web-sites has posted. Its inclusion on its web-sites of any
            third-party content or a link to a third-party is not an endorsement of
            that content or any web-site to which its web-sites contain links.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">12. Wallet</h6>
        <p>
            12.1. "KYC" stands for Know the Customer and refers to the various norms,
            rules, laws and statutes issued by RBI from time to time under which OASYS
            is required to procure personal identification details from the Customer
            before any services can be delivered and/or the Wallet shall be opened by
            OASYS issued to the Customer. Know Your Customer (KYC) documents may be
            required by OASYS from the Customer at the time of registration and/or on a
            later date and/or from time to time, for availing and/or continuation of
            the Wallet. You agrees that OASYS before entering into any relationship
            including, account based relationship, will carry out due diligence as
            required under Know Your Customer.
        </p>
        <p>
            12.2. Any wallet services provided by OASYS is a closed looped prepaid
            instrument, under RBI regulated Payment and Settlement System Act 2007, and
            is linked to your registered Mobile Number. The value in the wallet can be
            used by you for usage within OASYS formats only.Any wallet not transacting
            for 6 months or more may be treated as expired and the value may be either
            forfeited or shall be refunded to the your registered bank account with
            OASYSafter deducting applicable charges, if any. Such wallet user will be
            intimated through SMS information to this effect in accordance with
            regulatory frame work prevailing and amended from time to time.
        </p>
        <p>
            12.3. The record of transactions maintained by OASYS shall be conclusive
            and binding on You.
        </p>
        <p>
            12.4. Customer hereby grants and confirms the existence of the right of
            lien and set-off with OASYS, which OASYS may at any time without prejudice
            to any of its specific rights under any other agreements with the Customer,
            at its sole discretion and without notice to Customer utilize to
            appropriate any monies belonging to such Customer and lying/deposited with
            OASYS or due by OASYS to the Customer, towards any of OASYS’s dues,
            overdrawings/overdrafts and outstandings, including any charges/fees/dues
            payable under these Terms and Conditions.
        </p>
        <p>
            12.5. OASYS shall also be entitled to freeze operations in the account of a
            customer and/or to the right of lien and set-off with or without notice, if
            OASYS is of the view and/or suspects any omission and/or commission
            including but not limited to any malafides / fraud / mischief / hacking /
            unauthorized access, etc., for such period as it may deemed fit until it
            has received to its satisfaction the necessary clarifications as sought
            from the Customer and/or until it is convinced that operations in the
            account can recommence.
        </p>
        <p>
            12.6. The passbook would reflect all the transactions performed through the
            Wallet and SMS alerts would be sent to You for every transaction done in
            the Wallet.
        </p>
        <p>
            12.7. The Wallet shall expire or closed at the time of terminating the
            services with OASYS.
        </p>
        <p>
            <strong>13. </strong>
            You agree that OASYS will not be responsible or liable for any loss or
            damage of any sort incurred as the result of any of your transactions.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">14. Service Fee and Commission:</h6>
        <p>
            14.1. You shall be charged a certain amount as Service Fee for availing any
            service(s) provided by OASYS. Any such amounts to be paid by You as Service
            Fee shall be deducted from the Wallet maintained by You with OASYS.
        </p>
        <p>
            14.2. You may be eligible to receive a certain amount as commission for the
            services rendered by You. Such commission shall differ based upon the
            transactions performed by You. The commissions shall be subject to the
            policies and regulations implemented by RBI. Any commission which You are
            eligible to receive shall be credited to Your Wallet.
        </p>
        <p>
            14.3. The services offered by OASYS shall not be limited to the services
            offered at the time of Your registration. OASYS shall include and
            discontinue any services as and when it deems fit. Any such inclusion or
            discontinuation shall be notified to You.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">15. Recharge and Utility Services</h6>
        <p>
            15.1. OASYS Recharges
        </p>
        <p>
            OASYS is only a reseller of digital products. OASYS does not provide mobile
            operator services and is only a reseller of prepaid mobile recharge
            services which are ultimately provided by telecommunications service
            providers (hereafter <span class="font-wt-600">Telco or Telcos</span>) or by other
            distributors or aggregators of such Telcos. OASYS is not a warrantor,
            insurer, or guarantor of the services to be provided by the Telcos. Prepaid
            mobile recharge sold by OASYS to You is sold without recourse against OASYS
            for any breach of contract by the Telcos. Any disputes regarding the
            quality, minutes provided, cost, expiration, or other terms of the mobile
            prepaid recharge purchased must be handled directly between You (or the
            recipient of the recharge) and the Telco. The terms and conditions set out
            in this section are applicable, <em>mutatis mutandis</em>, to other prepaid
            recharge products available on the OASYS Platform including prepaid
            recharges in relation to DTH, data card and toll tags as well as to other
            prepaid recharge products that may be offered on the OASYS Platform. OASYS
            will not be responsible for any failure on the part of any of its recharge
            partners in effecting a recharge.
        </p>
        <p>
            15.2. Refund Policy
        </p>
        <p>
            All sales of prepaid recharge on the OASYS Platform are final with no
            refund or exchange permitted. You are responsible for the mobile number or
            DTH account number for which You purchase the prepaid recharge and all
            charges that result from those purchases. You are also responsible for the
            information relating to data card and toll-tag recharge and all charges
            that result from those purchases. OASYS is not responsible for any purchase
            of prepaid recharge for an incorrect mobile number or DTH account number or
            incorrect toll or data card information. However, if in a transaction
            performed by You on the OASYS Platform, money has been charged to Your card
            or bank account and a recharge is not delivered within 24 hours of the
            completion of the transaction, then You shall inform us by sending an
            e-mail to our customer services e-mail address mentioned on the ‘Contact
            Us’ page on the OASYS Platform. Please include in the e-mail the following
            details – the mobile number (or DTH account number or data card or toll-tag
            information), operator name, recharge value, transaction date and order
            number. OASYS will investigate the incident and, if it is found that money
            was indeed charged toYour card or bank account without delivery of the
            recharge, then You will be refunded the money within 21 working days from
            the date of receipt of Your e mail. All refunds will be credited to Your
            Wallet. You can trigger a request in Your OASYS Wallet to transfer the
            money from Your OASYS wallet back to Your Bank Account. It will take
            maximum 3-21 working days for the money to show in Your bank account
            depending on Your bank’s policy.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">16. Your conduct on its web-sites and application:</h6>
        <p>
            <span class="font-wt-600"></span>
        </p>
        <p>
            i. If OASYS request registration information from you, you will provide
            data with true, accurate, current, and complete information.
        </p>
        <p>
            ii. You will promptly update your registration to keep it accurate,
            current, and complete.
        </p>
        <p>
            iii. If OASYS issues a password or OTP, you must not reveal it to anyone
            else for the safety of your account.
        </p>
        <p>
            iv. You are responsible for maintaining the confidentiality of your
            accounts and passwords.
        </p>
        <p>
            v. You agree to immediately notify OASYS of any unauthorized use of your
            passwords or accounts or any other breach of security.
        </p>
        <p>
            vi. You should always exit from your account at the end of each session.
        </p>
        <p>
            vii. OASYS will not be responsible for any loss or damage that may result
            if you fail to comply with these requirements.
        </p>
        <p>
            viii. The technology and the software underlying its web-sites or installed
            on Mobile/POS/Tablet terminal and the Services is the property of OASYS.
        </p>
        <p>
            ix. You agree not to copy, modify, rent, lease, loan, sell, assign,
            distribute, reverse engineer, grant a security interest in, or otherwise
            transfer any right to the technology or software underlying its web-sites
            or the Services.
        </p>
        <p>
            x. You agree not to modify the software underlying its web-sites or
            installed on Mobile/POS/Tablet terminal in any manner or form or to use
            modified versions of such software, including (without limitation) for the
            purpose of obtaining unauthorized access to its web-sites or applications.
        </p>
        <p>
            xi. Without limiting the foregoing, you agree that you will not use the
            services offered through its web-sites or applications to take any of the
            following actions:
        </p>
        <p>
            a. Obscene, offensive, indecent, racial, hatred to religion, anti-national,
            objectionable, defame, abuse, harass, stalk, threaten, or otherwise violate
            the legal right of others.
        </p>
        <p>
            b. Publish, post, upload, e-mail, distribute, or disseminate (collectively,
            “Transmit”) any inappropriate, profane, defamatory, infringing, obscene,
            indecent, or unlawful content.
        </p>
        <p>
            c. Transmit files that contain viruses, corrupted files, or any other
            similar software or programs that may damage or adversely affect the
            operation of another person’s computer, its web-sites, any application, any
            software or hardware, or telecommunications equipments;
        </p>
        <p>
            d. Advertise or offer to sell any goods or services for any commercial
            purpose unless you have its written consent to do so;
        </p>
        <p>
            e. Transmit web-sites, application, services, products, surveys, contests,
            pyramid schemes, spam, unsolicited advertising or promotional materials, or
            chain letters;
        </p>
        <p>
            f. Download any file, recompile, or disassemble or otherwise affect its
            products that you know or reasonably should know cannot be legally obtained
            such manner;
        </p>
        <p>
            g. Falsify or delete any author attributions, legal or other proper notices
            or proprietary designations or labels of the origin or the source of
            software or other material;
        </p>
        <p>
            h. Restrict or inhibit any other user from using and enjoying any public
            area within its web-sites, applications or software;
        </p>
        <p>
            i. Collect or store personal information about other end users;
        </p>
        <p>
            j. Interfere with or disrupt its web-sites &amp; Applications, servers, or
            networks;
        </p>
        <p>
            k. Forge headers or manipulate identifiers or other data in order to
            disguise the origin of any content transmitted through its web-sites &amp;
            Applications or to manipulate your presence on its web-sites &amp;
            Applications;
        </p>
        <p>
            l. Take any action that imposes an unreasonably or disproportionately large
            load on its infrastructure/network;
        </p>
        <p>
            xii. You agree to use its bulletin board services, chat areas, news groups,
            forums, communities and/or messages or communication facilities
            (collectively, the “Forums”) only to send and receive messages and material
            that are proper and related to that particular Forum.
        </p>
        <p>
            xiii. You agree not to access its web-sites &amp; Applications by any means
            other than through the interface provided by OASYS for use in accessing its
            web-sites &amp; Applications.
        </p>
        <p>
            xiv. You agree not to use any automated means, including, without
            limitation, agents, robots, scripts, or spiders, to access, monitor, or
            copy any part of its web-sites &amp; Applications, except those automated
            means that OASYS have approved in advance and in writing.
        </p>
        <p>
            xv. Use of its web-sites &amp; Applications is subject to existing laws and
            the Terms. Any violation by you of any terms of this Terms &amp; Condition
            or the laws of land may result in severe civil and criminal prosecution
            under respective laws, penalties, forfeiture of the Wallet Balance,
            suspension of your account, blacklisting to the all OASYS web-sites &amp;
            Applications, and intimation/disclosure to the third to third parties to
            whom OASYS deems necessary. Any violators may be prosecuted to the maximum
            extent possible.
        </p>
        <p>
            xvi. All right reserved. Any rights not expressly granted herein are
            reserved.
        </p>
        <p>
            <strong>17. </strong>
            OASYS reserves the right to record telephonic conversations with Customers
            for internal quality control purposes and as an evidence in case of any
            disputes.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">18. Amendments to the Terms:</h6>
        <p>
            OASYS may amend and modify the Terms and Conditions from time to time,
            including any and all documents and policies incorporated thereto without
            any prior notice to you. You agree to be bound by any of the changes made
            in the Terms, including changes made in the Terms, including changes to any
            and all documents and policies incorporated thereto. In case of continuing
            to access the POS Terminal and to provide the Services will indicate your
            acceptance of the amended Terms. If you do not agree with any of the
            amended Terms, then you must avoid any further use of the Terminal and
            provision of the Services. OASYS advises you to periodically read these
            Terms and Conditions, as it may change from time to time.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">19. Confidentiality:</h6>
        <p>
            You undertake that you shall treat as confidential all Confidential
            Information of OASYS and shall not disclose such Confidential Information
            to any third party without the written consent of OASYS. “Confidential
            Information” herein shall mean any technical, business, or proprietary
            information disclosed by OASYS to you or which may come into your knowledge
            or possession, directly or indirectly, including, but not limited to,
            information regarding business strategies and practices, methodologies,
            trade secrets, know-how, pricing, technology, and software. Further OASYS
            proprietary, technology and software products, and the pricing and these
            Terms are Confidential Information of OASYS.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">20. Ownership and Intellectual Property Rights:</h6>
        <p>
            All existing and future rights, including copyright and other Intellectual
            Property Rights, in the content of web-sites and Applications are owned or
            reserved or controlled by OASYS. The visual interfaces, graphics, design,
            compilation, information, computer code (including source code and object
            code), products, software, services, and all other elements provided by
            OASYS are protected under the applicable laws of the land. OASYS reserves
            all rights not expressly granted in this Agreement.
        </p>
        <h6 class="sub-headings1 m-b-30 m-t-30">21. Disclaimers:</h6>
        <p>
            The information, software, products and services included in or available
            through the OASYS sites/apps/applications/services may include
            interruptions, inconsistencies, errors, inaccuracies or typographical
            errors. Changes are periodically made to the OASYS sites/services &amp;
            installed applications and to the information therein. OASYS make no
            representations and warranties about the suitability, reliability,
            availability, timeliness, lack of viruses or other harmful components and
            accuracy of the information, software, products, services and related
            graphics contained within the OASYS sites/apps/applications/services for
            any purpose. All such information, software, product, services and related
            graphics are provided “as is” without warranty of any kind.
        </p>
        <h6 class="sub-headings1 m-b-30 m-t-30">22. Indemnification:</h6>
        <p>
            You agree to indemnify, save and hold OASYS, its group companies,
            subsidiaries, partners, affiliates, officers, directors and employees,
            harmless from any and all claims, demands, damages, losses, judgments,
            liabilities, costs and expenses (including reasonable legal expenses and
            attorneys' fees) asserted by any third party due to or arising out of your
            use of or conduct on the any of the OASYS’s websites or apps or any other
            application.
        </p>
        <h6 class="sub-headings1 m-b-30 m-t-30">23. Dispute Resolution:</h6>
        <p>
            23.1. In case the Parties, despite their efforts, are unable to amicably
            resolve the disputes of differences, then all such disputes and differences
            including claims and demands arising out of or in connection with this
            Agreement shall be referred to a sole arbitrator to be appointed by OASYS.
        </p>
        <p>
            23.2. OASYS may elect to resolve any controversy or claim arising out of or
            relating to these Terms or its web-sites &amp; Application or by binding
            arbitration in accordance with the Arbitration and Conciliation Act, 1996
            and the amendments thereof. Any such controversy or claim shall be
            arbitrated on an individual basis and shall not be considered in any
            arbitration with any claim or controversy of any other party.
        </p>
        <p>
            23.3. The award passed by the Arbitrator shall be final and binding in all
            the Parties. The seat and the venue of Arbitration shall be at Chennai. The
            Arbitration will be conducted in English language.
        </p>
        <h6 class="sub-headings1 m-b-30 m-t-30">24. Limitation of Liability:</h6>
        <p>
            OASYS, including its officers, directors, shareholders, employees,
            sub-contractors, business associates, group companies, sister companies,
            subsidiaries and other affiliates will not be liable to the maximum extent
            permitted by the applicable law, for any direct, indirect, incidental, or
            consequential damage, or any other damage and loss (including loss of
            profit, loss of data and work stoppage), costs, expenses and payments,
            regardless of the alleged liability or form of action, whether in contract,
            tort or otherwise, including negligence, intellectual property
            infringement, product liability and strict liability, that may result from,
            or in connection with the use of POS Terminal or the inability to access
            the POS Terminal and provision of the services, or from any failure, error,
            or downtime in the function of the services, or from any fault or error
            made by OASYS’s staff, or from your reliance on content delivered through
            the services, or from the nature of content delivered through the services
            or from any communication with OASYS or from any denial or cancellation of
            request for information through the services, or from retention, deletion,
            disclosure or any other use or loss of content through the services,
            regardless of whether OASYS has been advised of the possibility of such
            damages. In any event, your sole remedy will be limited to the corrections
            of such errors, as deemed fit by OASYS in its sole discretion. Without
            prejudice to aforesaid, it is hereby acknowledged by you that the aggregate
            liability of OASYS, for any reason whatsoever will not exceeded INR1000
            (Rupees One Thousand only) or the total cost paid by the customer under the
            transaction in dispute, whichever is lower.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">25. Severability:</h6>
        <p>
            If any provision of this Agreement is held by a court of competent
            jurisdiction to be contrary to Law, then the remaining provisions of this
            Agreement, if capable of substantial performance, shall remain in full
            force and effect.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">26. Governing Law and Jurisdiction</h6>
        <p>
            This Agreement, including all matters relating to it shall be governed by,
            and construed in accordance with, the laws of the India. Any action or
            proceeding arising out of or relating to this Agreement including any
            injunctive relief shall be brought and maintained exclusively in the
            competent courts at Chennai.<span class="font-wt-600"></span>
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">27. Privacy Policy</h6>

        <p>
            All the information that OASYS collects from you, such as registration
            details, account details, personal details while on-boarding is subject to
            its Privacy Policy. Please read and acknowledge the Privacy Policy of
            OASYS.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">28. Waiver and Severability of Terms:</h6>
        <p>
            The failure of OASYS to exercise or enforce any right or provision of the
            Terms shall not constitute a waiver of such right or provision. If any
            provision of the Terms is found by a court of competent jurisdiction to be
            invalid, the parties nevertheless agree that the court should endeavour to
            give effect to the parties’ intentions as reflected in the provision, and
            the other provisions of the Terms remain in full force and effect.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">29. Survival: </h6>
        <p>
            You agree and confirm that the indemnities, confidentiality obligations,
            limitation of liability, disclaimer, dispute resolution mechanism, shall
            survive the efflux of time.
        </p>
        <h6 class="sub-headings1 m-t-30 m-b-30">30. Contact:</h6>
        <p>
            Any complaint on the services provided by OASYS or if You want to send
            notices, You may contact us at customercare@oasyspay.in.
        </p>
        <p>
            <!-- ©2019 OASYS Cybernetics Private Limited., All Rights Reserved -->
        </p>
    </div>
</div>