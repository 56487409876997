import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-insurance',
    templateUrl: './insurance.component.html',
    styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {

    @ViewChild('focusHospicash')focusHospicash: ElementRef;
    @ViewChild('focusInsurance')focusInsurance: ElementRef;
    services: string;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        this.services = localStorage.getItem('services');
    }

    ngAfterViewInit() {
        if (this.services == 'hospicash') {
            this.focusHospicash.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        }
        if (this.services == 'insurance') {
            this.focusInsurance.nativeElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
        }
    }

    ngOnDestroy() {
        localStorage.setItem('services', '');
    }

}