<!-- Start Page Title Area -->
<div class="page-title-area item-bg-insurance jarallax">
    <div class="container">
        <div class="page-title-content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start AEPS Services Area -->
<div class="services-area ptb-70" #focusHospicash>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content text-justify">
                    <h1 class="font-wt-700">Hospicash</h1>
                    <div class="bar"></div>
                    <p>Hospicash is a type of insurance policy that provides financial coverage for daily hospitalization expenses incurred during a hospital stay. It offers a fixed daily cash benefit to the policyholder for each day spent in the hospital due to illness or injury, regardless of the actual medical expenses incurred.</p>
                    <p>Hospicash policies typically have predefined daily benefit amounts and maximum coverage limits, which vary depending on the insurance provider and the specific policy terms. Policyholders can choose the coverage amount based on their individual needs and budget. </p>
                    <p> The benefits provided by Hospicash insurance are paid directly to the policyholder or their designated beneficiary, offering flexibility in how the funds are used to meet hospitalization-related expenses.</p>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <!-- style="max-width: 70% !important;" -->
                    <img src="assets/img/products-services/Hospicash.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 72% !important;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End AEPS Services Area -->

<!-- Start Micro ATM Services Area -->
<div class="services-area ptb-70 bg-f7fafd" #focusInsurance>
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/products-services/Accidental.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle2.png" alt="image" style="max-width: 75% !important;">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content text-justify">
                    <h1 class="font-wt-700">Accidental</h1>
                    <div class="bar"></div>
                    <p>Accidental insurance is a type of insurance policy that provides financial protection in the event of accidental death, disability, or injury. This insurance coverage offers a lump sum payment or periodic benefits to the policyholder or their beneficiaries in case of an accident resulting in covered losses.</p>
                    <p>Accidental insurance serves as a financial safety net, providing peace of mind and financial security to individuals and families in the event of an unexpected accident. It helps mitigate the financial impact of accidents by covering medical expenses and providing compensation for disability ensuring that policyholders and their loved ones are adequately protected financially. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Micro ATM Services Area -->

<!-- <app-funfact></app-funfact>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->